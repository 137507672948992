import React, { useEffect, useState, Fragment } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
// Import Dialog from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// material
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// api
import * as Services from "_config/api";
import { envConfig, eduTechConfig } from "_config/config";
import { common, restServices, eduTechSerivces, history } from "_helpers";
import { FormFieldAuto } from "pages/_components/FormFields";
import Loader from "_components/_loader/Loader";
import "./includes/DynamicForm.css";

const selectDefault = { label: "", value: "" };

function EduTechLead(props) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [status, setStatus] = useState("process");
  const [fields, setFields] = useState({}); //crm lead response
  const [univeristies, setUniveristies] = useState([]);
  const [course1List, setCourse1List] = useState([]);
  const [course2List, setCourse2List] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [imgPreview, setImgPreview] = useState(false);
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [disableSuggestion, setDisableSuggestion] = useState(false);
  const [leadStatus, setLeadStatus] = useState(eduTechConfig.LeadStatus);

  // // effect
  useEffect(() => {
    if (props.data.value1 == "") {
      history.push("/crm/lead");
    } else {
      setFields(props.data.leadData);
      setCRMLeadData(props.data.leadData);
      getETUniversities();
      //getColumnsData();
    }
  }, []);

  // #Api#
  const getColumnsData = () => {
    let filter = `?q=EserviceCode=${envConfig.ESERVICE_LEAD_CODE}`;
    let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let formFields = JSON.parse(response.items[0].FormFields);
          let jsonFields = formFields.form;
          if (jsonFields.fields) {
            let sourceKey = jsonFields.fields.filter(
              (obj) => obj.key == "LeadSource"
            );
            let options = [];
            if (sourceKey[0].attr && sourceKey[0].attr.option) {
              sourceKey[0].attr.option.map((obj) => {
                options.push({ label: obj, value: obj });
              });
              setLeadSource(options);
            }
          }
        }
      },
      (error) => {}
    );
  };

  //set CRM lead value
  const setCRMLeadData = (data) => {
    let newFields = common.reParse(data);
    newFields.AppCourse = data.Clattr1
      ? JSON.parse(data.Clattr1)
      : { course: "", university: "", month: "", year: "" };
    newFields.EduData = data.Clattr2 ? JSON.parse(data.Clattr2) : "";
    newFields.workData = data.Clattr3 ? JSON.parse(data.Clattr3) : "";
    newFields.testData = data.Clattr4 ? JSON.parse(data.Clattr4) : "";
    newFields.Cattr19 =
      data.LeadStatus == "Missing Info" && data.Cattr19
        ? common.getMissingFields(data.Cattr19, true)
        : ""; //Missing Fields
    newFields.Cattr23 = data.LeadStatus == "Missing Info" ? data.Cattr23 : ""; //Missing Info Description
    if (data.Cattr33) {
      setDisableSuggestion(true);
    }

    newFields.CourseNameS1 = selectDefault;
    newFields.UniversityNameS1 = selectDefault;

    newFields.CourseNameS2 = selectDefault;
    newFields.UniversityNameS2 = selectDefault;

    newFields.isUpdate = false;

    let sCourses = data.Clattr5 ? JSON.parse(data.Clattr5) : [];
    if (sCourses.length > 0) {
      setDisableSuggestion(true);
      sCourses.map((course, idx) => {
        if (idx == 0) {
          newFields.CourseNameS1 = course.course;
          newFields.UniversityNameS1 = course.university;
        } else {
          newFields.CourseNameS2 = course.course;
          newFields.UniversityNameS2 = course.university;
        }
      });
    }

    newFields.SalespersonId = {
      label: data.SalespersonIdDesc,
      value: data.SalespersonId,
    };
    newFields.LeadSource = { label: data.LeadSource, value: data.LeadSource };
    setLeadStatus(common.enableLeadStatus(data.LeadStatus));
    newFields.LeadStatus = { label: data.LeadStatus, value: data.LeadStatus };
    setFields(newFields);

    if (data.Clattr5) {
      getUniversityColleges(newFields);
    }
    setStatus("completed");
  };

  //Get universities from edutech
  const getETUniversities = async () => {
    let result = await eduTechSerivces.getUniversities();
    let udata = common.getOptionsData(result, "name", "_id");
    setUniveristies(udata);
  };

  //Get courses from edutech
  const getETCourseByUId = async (universityId, idx, isEdit, leadData) => {
    let result = await eduTechSerivces.getCoursesbyUniversity(universityId);
    let optionsData = [];
    optionsData.push({ label: "Select Course", value: "" });

    if (result && result.length) {
      await result.map((obj) => {
        let options = {
          label: obj.name,
          value: obj,
        };
        optionsData.push(options);
      });
    }

    if (idx == 1) setCourse1List(optionsData);
    else setCourse2List(optionsData);
  };

  //Bind university and colleges list in selectbox
  const getUniversityColleges = (field) => {
    getETCourseByUId(field.UniversityNameS1.value, 1, true, field);
    getETCourseByUId(field.UniversityNameS2.value, 2, true, field);
  };

  const getResposiblePersonEmail = (empId) => {
    let url = encodeURI(
      envConfig.BASE_API +
        Services.CRM_PAY_EMPLOYEE +
        "/" +
        empId +
        "?fields=Email"
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let newFields = common.reParse(fields);
          newFields.responsiblePersonEmail = response.Email;
          setFields(newFields);
        }
      },
      (error) => {}
    );
  };

  // Form field auto handler
  const onSelect = (value, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = value || selectDefault;
    newFields.isUpdate = true;
    setFields(newFields);
    if (name == "SalespersonId" && value) {
      getResposiblePersonEmail(value.value);
    }
  };

  //text, textarea handler
  const onChange = (e) => {
    const { name, value } = e.target;
    let newFields = common.reParse(fields);
    newFields[name] = value;
    newFields.isUpdate = true;
    setFields(newFields);
  };

  const onFieldChange = (e, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = e.target.checked ? "Y" : "N";
    setFields(newFields);
  };

  // Select handler
  const onSelectHandler = (data, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = data;
    newFields.isUpdate = true;

    //Handle missing info
    if (name == "LeadStatus") {
      if (data.value == "Missing Info") {
        newFields.Cattr19 = [];
        newFields.Cattr23 = "";
      } else if (data.value == "Received Missing Info") {
        newFields.Cattr19 = [];
        newFields.Cattr23 = "";
      } else if (data.value == "Course Suggested" && disableSuggestion) {
        common.snack("E", "Already course suggested");
      }
    }

    if (name == "UniversityNameS1") {
      newFields.CourseNameS1 = selectDefault;
    } else if (name == "UniversityNameS2") {
      newFields.CourseNameS2 = selectDefault;
    }
    setFields(newFields);

    if (name == "UniversityNameS1" || name == "UniversityNameS2") {
      getETCourseByUId(data.value, name == "UniversityNameS1" ? 1 : 2, false);
    }
  };

  const previewImage = (e, file) => {
    e.preventDefault();
    setImgPreview(true);
    setImgPreviewSrc(file);
  };

  const handleClose = () => {
    setImgPreview(false);
    setImgPreviewSrc("");
  };

  //go back to list page
  const onCancel = () => {
    history.push("/crm/lead");
  };

  const update = async () => {
    let inputs = await formInputs();
    if (inputs && inputs.leadData && inputs.appData) {
      setIsSubmit(true);
      let url =
        envConfig.BASE_API + Services.CRM_LEADS + "/" + props.data.value1;

      restServices.patchRequest(
        url,
        inputs.leadData,
        async (response) => {
          window.scrollTo(0, 0);
          setIsSubmit(false);
          if (Object.keys(inputs.appData).length > 0) {
            await eduTechSerivces.updateApplicationInfo(
              fields.Cattr1,
              inputs.appData
            );
          }
          if (typeof props.onSave !== "undefined") {
            props.onSave(response);
          }
          common.snack("S", "Successfully Updated");
          setLeadStatus(common.enableLeadStatus(fields.LeadStatus.value));
        },
        (error) => {
          setIsSubmit(false);
        }
      );
    }
  };

  const formInputs = async () => {
    let appData = {};
    let uFields = {};

    //Responsible person
    if (fields.SalespersonId.value) {
      uFields.SalespersonId = fields.SalespersonId.value;
      appData.responsiblePersonEmail = fields.responsiblePersonEmail;
    }

    //Lead status
    if (fields.LeadStatus.value) uFields.LeadStatus = fields.LeadStatus.value;

    if (fields.LeadStatus.value == "Processing" && fields.Cattr24 == "Y") {
      //Missing Fields
      if (fields.Cattr19.length > 0 && fields.Cattr23) {
        let mfields = common.getMissingObject(fields.Cattr19);
        appData.status = "review_missing_info";
        appData.description = fields.Cattr23; //Missing Info Description
        appData.jAttr1 = mfields;

        let mfArray = fields.Cattr19.map((a) => a.label);
        uFields.Cattr23 = fields.Cattr23;
        uFields.Cattr19 = mfArray.join(",");
      } else {
        common.snack("E", "Plese fill the missing information");
        return null;
      }
    } 
    if (fields.LeadStatus.value == "Processing" && fields.Cattr26 == "Y") {
      let leadSCourse = [];
      let appSCourse = [];
      //Course1 Note
      if (fields.Cattr29) {
        uFields.Cattr29 = fields.Cattr29;
        appData.feedBack = fields.Cattr29;
      }

      if (fields.CourseNameS1.value && fields.UniversityNameS1.value) {
        leadSCourse.push({
          course: fields.CourseNameS1,
          university: fields.UniversityNameS1,
        });
        uFields.Cattr34 =
          fields.AppCourse.course +
          "," +
          fields.AppCourse.university +
          ", " +
          fields.AppCourse.year +
          ", " +
          fields.AppCourse.month;
        uFields.Cattr20 =
          fields.CourseNameS1.label + ", " + fields.UniversityNameS1.label;

        appSCourse.push({
          course: fields.CourseNameS1.value._id,
        });
      }
      if (fields.CourseNameS2.value && fields.UniversityNameS2.value) {
        leadSCourse.push({
          course: fields.CourseNameS2,
          university: fields.UniversityNameS2,
        });
        uFields.Cattr25 =
          fields.CourseNameS2.label + ", " + fields.UniversityNameS2.label;

        appSCourse.push({
          course: fields.CourseNameS2.value._id,
        });
      }

      // suggested Courses
      if (appSCourse.length == 2 && leadSCourse.length == 2) {
        uFields.Clattr5 = JSON.stringify(leadSCourse);
        appData.status = "review_missing_info";
        appData.feedBack = fields.Cattr29;
        appData.suggestedCourses = appSCourse;
      } else {
        common.snack("E", "Please fill the all details in suggested courses");
        return null;
      }
    } else if (fields.LeadStatus.value == "Complete Lead") {
      //clear missing fields
      uFields.Cattr19 = "";
      uFields.Cattr23 = "";

      appData.status = "review_completed";
      appData.description = "Review completed";
    } else if (fields.LeadStatus.value == "Application Rejected") {
      appData.status = "rejected";
      appData.description = "Application Rejected";
    }

    return {
      leadData: uFields,
      appData: appData,
    };
  };

  const renderAttachment = (docs) => {
    return (
      <div>
        {docs.map((image, idx) => {
          return ["jpg", "jpeg", "png"].includes(image.split(".").pop()) ? (
            <img
              key={idx}
              src={common.loadEduImage(image)}
              className="eduTechImg"
              onClick={(e) => previewImage(e, image)}
            />
          ) : (
            <div className="attach-link" key={idx}>
              <a href={common.loadEduImage(image)} download>
                <img src={common.loadImg("attachment.png")} />
                {image.split("/").pop()}
              </a>
            </div>
          );
        })}
      </div>
    );
  };

  let course = fields.Cattr33 ? fields.Cattr33.split(",") : [];

  // render
  return (
    <Fragment>
      {status == "process" ? (
        <Loader />
      ) : (
        status == "completed" && (
          <Fragment>
            <Row className="mb-3">
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Application Information</h2>
                </div>
                <Row>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Application ID</Form.Label>{" "}
                      {fields.Cattr1 || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Student Email</Form.Label>{" "}
                      {fields.Email || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Agent Email</Form.Label>
                      {fields.Cattr27 && fields.Cattr28
                        ? fields.Cattr27 + " & " + fields.Cattr28
                        : "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Application Date</Form.Label>{" "}
                      {fields.Dattr2
                        ? common.formatDate(fields.Dattr2, "DD-MM-YYYY HH:MM a")
                        : ""}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Responsible Person</Form.Label>
                    <FormFieldAuto
                      isClearable={false}
                      isRender={true}
                      name="SalespersonId"
                      type="FindEmployee"
                      value={fields.SalespersonId}
                      onSelect={onSelect}
                    />
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Lead Source</Form.Label>
                      {fields.LeadSource.value || "-"}
                      {/* <Select
                      defaultValue={fields["LeadSource"]}
                      onChange={(data) => onSelectHandler(data, "LeadSource")}
                      options={leadSource}
                    ></Select> */}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Application Status</h2>
                </div>
                <Row className="mb-3">
                  <Col sm={3}>
                    <Form.Label>Lead Status</Form.Label>
                    <Select
                      onChange={(data) => onSelectHandler(data, "LeadStatus")}
                      options={leadStatus}
                      isOptionDisabled={(option) => option.disabled}
                      defaultValue={fields["LeadStatus"]}
                    />
                  </Col>
                  {fields.LeadStatus.value == "Processing" && (
                    <Col sm={9}>
                      <Form.Group className="mt-4">
                        <FormControlLabel
                          value={fields.Cattr24}
                          control={<Checkbox color="primary" />}
                          label={"Is Missing Information?"}
                          labelPlacement="end"
                          checked={fields.Cattr24 === "Y" ? true : false}
                          onChange={(e) => onFieldChange(e, "Cattr24")}
                        />
                        <FormControlLabel
                          value={fields.Cattr26}
                          control={<Checkbox color="primary" />}
                          label={"Course Suggestion?"}
                          labelPlacement="end"
                          checked={fields.Cattr26 === "Y" ? true : false}
                          onChange={(e) => onFieldChange(e, "Cattr26")}
                        />
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                {fields.Cattr24 == "Y" && (
                  <>
                    <Row>
                      <Col className="sub-head-title">
                        <h2>Missing Information</h2>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Missing Fields</Form.Label>
                          <Select
                            name="Cattr19"
                            defaultValue={fields.Cattr19}
                            isMulti={true}
                            onChange={(data) =>
                              onSelectHandler(data, "Cattr19")
                            }
                            options={eduTechConfig.missingFields}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Missing Information Description
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            name="Cattr23"
                            rows={2}
                            value={fields.Cattr23}
                            onChange={(e) => onChange(e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
                {fields.Cattr26 == "Y" && !disableSuggestion && (
                  <>
                    <Row>
                      <Col className="sub-head-title">
                        <h2>Suggested Course 1</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Label>University Name </Form.Label>
                        <Select
                          options={univeristies}
                          name="UniversityNameS1"
                          defaultValue={fields["UniversityNameS1"]}
                          onChange={(data) =>
                            onSelectHandler(data, "UniversityNameS1")
                          }
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Label>Course Name:</Form.Label>
                        <Select
                          options={course1List}
                          name="CourseNameS1"
                          defaultValue={fields["CourseNameS1"]}
                          onChange={(data) =>
                            onSelectHandler(data, "CourseNameS1")
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="sub-head-title">
                        <h2>Suggested Course 2</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Label>University Name </Form.Label>
                        <Select
                          options={univeristies}
                          name="UniversityNameS2"
                          defaultValue={fields["UniversityNameS2"]}
                          onChange={(data) =>
                            onSelectHandler(data, "UniversityNameS2")
                          }
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Label>Course Name:</Form.Label>
                        <Select
                          options={course2List}
                          name="CourseNameS2"
                          defaultValue={fields["CourseNameS2"]}
                          onChange={(data) =>
                            onSelectHandler(data, "CourseNameS2")
                          }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="sub-head-title">
                        <h2>Applied Course Not Eligiblity Reason</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Reason</Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Why applied course not eligible?"
                            rows={3}
                            value={fields["Cattr29"]}
                            name={"Cattr29"}
                            onChange={(e) => onChange(e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Course Histroy</h2>
                </div>
                <Row>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Applied Course</Form.Label>{" "}
                      {fields.AppCourse.course || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>University</Form.Label>{" "}
                      {fields.AppCourse.university || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Intake</Form.Label>
                      {fields.AppCourse.month || "-"} &{" "}
                      {fields.AppCourse.year || "-"}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {fields.Cattr33 && (
                    <>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>Previously Applied Course</Form.Label>{" "}
                          {course[0] || "-"}
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>University</Form.Label> {course[1] || "-"}
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>Intake</Form.Label>
                          {course[2] || "-"} & {course[3] || "-"}
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </Row>
                {fields.Cattr20 && (
                  <Row>
                    <Col sm={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Suggested Courses</Form.Label>{" "}
                        {fields.Cattr20} <br />
                        {fields.Cattr25}
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>General Information</h2>
                </div>
                <Row>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      {fields.Cattr3 || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Last Name</Form.Label>
                      {fields.Cattr4 || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      {fields.Email || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Mobile</Form.Label>
                      {fields.Mobile || "-"}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Dob</Form.Label>
                      {fields
                        ? common.formatDate(fields.Dattr1, "DD-MM-YYYY")
                        : "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Gender</Form.Label>
                      {fields.Cattr6 || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Nationality</Form.Label>
                      {fields.Cattr7 || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>First Language</Form.Label>
                      {fields.Cattr8 || "-"}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Marital Status</Form.Label>
                      {fields.Cattr9 || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Passport Number</Form.Label>
                      {fields.Cattr10 || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Address Line 1</Form.Label>
                      {fields.Cattr11 || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Country</Form.Label>
                      {fields.Cattr12 || "-"}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>province</Form.Label>
                      {fields.Cattr5 || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>City/Town</Form.Label>
                      {fields.Cattr13 || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Postcode</Form.Label>
                      {fields.Cattr14 || "-"}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Educational Information</h2>
                </div>
                <Row>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Country of Education</Form.Label>
                      {fields.Cattr16 || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Highest Level of Education</Form.Label>
                      {fields.Cattr17 || "-"}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Grade</Form.Label>
                      {fields.Cattr18 || "-"}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    {fields.EduData &&
                      fields.EduData.map((college, idx) => {
                        return (
                          <Row className="row-space" key={idx}>
                            <Col sm={12}>
                              <div className="education-count">
                                <h4>Education</h4>
                              </div>
                            </Col>
                            <Col sm="3">
                              <Form.Label>Name of Institution</Form.Label>
                              {college.institutionName || "-"}
                            </Col>
                            <Col sm={3}>
                              <Form.Label>Country of Education</Form.Label>{" "}
                              {college.institutionCountry || "-"}
                            </Col>
                            <Col sm={3}>
                              <Form.Label>Qualification</Form.Label>
                              {college.qualification || "-"}
                            </Col>
                            <Col sm={3}>
                              <Form.Label>Degree Name</Form.Label>
                              {college.degree || "-"}
                            </Col>
                            <Col sm={3}>
                              <Form.Label>Score</Form.Label>
                              {college.score || "-"}
                            </Col>
                            <Col sm={3}>
                              <Form.Label>Grading System</Form.Label>
                              {college.gradingSystem || "-"}
                            </Col>
                            <Col sm={3}>
                              <Form.Label>Graduation Date</Form.Label>
                              {college.graduationDate
                                ? common.formatDate(
                                    college.graduationDate,
                                    "DD-MM-YYYY"
                                  )
                                : "-"}
                            </Col>
                            <Col sm={3}>
                              <Form.Label>Start Date</Form.Label>
                              {college.startDate
                                ? common.formatDate(
                                    college.startDate,
                                    "DD-MM-YYYY"
                                  )
                                : "-"}
                            </Col>
                            <Col sm={3}>
                              <Form.Label>End Date</Form.Label>
                              {college.endDate
                                ? common.formatDate(
                                    college.endDate,
                                    "DD-MM-YYYY"
                                  )
                                : "-"}
                            </Col>
                            <Col sm={3} className="col-attachment">
                              <Form.Label>Documents</Form.Label>
                              {renderAttachment(college.docs)}
                            </Col>
                          </Row>
                        );
                      })}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>English Exam Scores</h2>
                </div>
                <Row>
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      {fields.testData &&
                        fields.testData.map((test, idx) => {
                          return (
                            <Row className="row-space" key={idx}>
                              <Col sm={12}>
                                <div className="test-count">
                                  <h4>Exam</h4>
                                </div>
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Exam Type</Form.Label>{" "}
                                {test.examType}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Date of Issue</Form.Label>
                                {test.dateOfIssue
                                  ? common.formatDate(
                                      test.dateOfIssue,
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}{" "}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Listening</Form.Label>{" "}
                                {test.listening || "-"}{" "}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Reading</Form.Label>{" "}
                                {test.reading || "-"}{" "}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Writing</Form.Label>{" "}
                                {test.writing || "-"}{" "}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Speaking</Form.Label>{" "}
                                {test.speaking || "-"}
                              </Col>
                              <Col sm={3} className="col-attachment">
                                <Form.Label>Documents</Form.Label>
                                {renderAttachment(test.docs)}
                              </Col>
                            </Row>
                          );
                        })}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Background Information</h2>
                </div>
                <Row>
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Did you apply for a visa before as a part of your entry
                        clearance application?
                      </Form.Label>
                      {fields.Cattr21 == "Y" ? "Yes" : "No"}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Did your visa have been rejected before as a part of
                        your entry clearance application?
                      </Form.Label>
                      {fields.Cattr22 == "Y" ? "Yes" : "No"}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-100">
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Work History</h2>
                </div>
                <Row>
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      {fields.workData &&
                        fields.workData.map((exp, idx) => {
                          return (
                            <Row className="row-space" key={idx}>
                              <Col sm={12}>
                                <div className="exp-count">
                                  <h4>Experience</h4>
                                </div>
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Company Name</Form.Label>{" "}
                                {exp.companyName || "-"}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Designation</Form.Label>{" "}
                                {exp.designation || "-"}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Joining Date</Form.Label>
                                {exp.joinDate || "-"}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>End Date</Form.Label>
                                {exp.endDate || "-"}
                              </Col>
                              <Col sm={3} className="col-attachment">
                                <Form.Label>Documents</Form.Label>
                                {renderAttachment(exp.docs)}
                              </Col>
                            </Row>
                          );
                        })}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="leadfooter-btn">
              <Row className={!props.quick ? "botBtn" : ""}>
                <Col sm={12}>
                  <Button
                    className="saveBtn"
                    type="submit"
                    onClick={() => update()}
                    disabled={false}
                  >
                    {isSubmit ? "Processing..." : "Update"}
                  </Button>
                  <Button
                    className="cancelBtn"
                    type="button"
                    onClick={() => onCancel()}
                    disabled={isSubmit}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>

            {imgPreview && (
              <Dialog
                open={imgPreview}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="false"
                minWidth="false"
              >
                <DialogTitle
                  id="responsive-dialog-title"
                  className="popup-title"
                >
                  <a href={common.loadEduImage(imgPreviewSrc)} download>
                    Download
                  </a>{" "}
                  {"  "}
                  <Link to="#" onClick={handleClose}>
                    Close
                  </Link>
                </DialogTitle>
                <DialogContent>
                  <div className="popup-form-wrap imgDlgContent">
                    <div className="list-row text-center">
                      <img src={imgPreviewSrc || null} alt="image" />
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            )}
          </Fragment>
        )
      )}
    </Fragment>
  );
}

export default EduTechLead;
