import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import MuiSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { restServices, history, common } from "_helpers";
import Loader from "_components/_loader/Loader";
// includes
import serviceList from "crm/files/serviceList.json";
import { crm } from "_utils";
import AccessDenied from "_components/_accessdenied/AccessDenied";

class ConversionMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      AttrMappingId: 0,
      modules: [],
      mappedData: [],
      srcData: [],
      desData: [],
      selModule: "",
      isSubmit: false,
      userPermissions: common.getPermission(
        props,
        "configure:conversion mapping"
      ),
    };
  }
  componentDidMount() {
    let cModules = [];
    Object.keys(serviceList).map((item, key) => {
      let itemInfo = serviceList[item];
      if (itemInfo.conversion) {
        cModules.push({
          label: itemInfo.label,
          value: itemInfo.conversionObject,
          api: itemInfo.api,
          code: itemInfo.code,
          mapping: itemInfo.conversionMapping,
          name: itemInfo.conversionName,
        });
      }
    });
    this.setState({ modules: cModules, selModule: cModules[0].value }, () => {
      this.getHdrMappingData(cModules[0].api);
    });
  }

  // #Handler#
  onModuleSelect = (e) => {
    const { modules } = this.state;
    let value = e.target.value;
    localStorage.setItem("selModule", value);
    this.setState({ selModule: value });
    let sModule = modules.filter((item) => item.value == value);
    this.getHdrMappingData(sModule[0].api);
  };

  onDefaultOption = (e, item) => {
    const { srcData } = this.state;
    //e.preventDefault();
    let index = srcData.findIndex((sData) => sData.key == item.key);

    if (index >= 0) {
      item.SourceColumnName = item.key;
      item.MappingColumnName = e.target.value;
      srcData[index] = item;
      this.setState({
        srcData,
      });
    }
  };

  getHdrMappingData = (code) => {
    const { modules } = this.state;
    this.setState({
      isLoading: true,
    });
    let filter = `?q=SourceObject=${Services[code]}&expand=CholaAttrMappingDtlView&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.CHOLA_CUSTOM_MAPPING_HDR + filter;
    restServices.getRequest(
      url,
      (response) => {
        if (response.items.length > 0) {
          let result = response.items[0];

          this.setState({
            AttrMappingId: result.AttrMappingId,
            mappedData: result.CholaAttrMappingDtlView,
          });
          let sModule = modules.filter((item) => item.api == code);
          this.getColumnsData(true, sModule[0].code);
        }
      },
      (error) => {}
    );
  };

  getColumnsData = (isSrc, code) => {
    const { modules } = this.state;
    let filter = `?q=EserviceCode=${code}`;
    let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let fields = JSON.parse(response.items[0].FormFields);
          let form = fields.form;
          let fList = form.fields;
          let result = [];
          fList.map((obj) => {
            if (
              obj.key.includes("Clattr") ||
              obj.key.includes("Cattr") ||
              obj.key.includes("Dattr") ||
              obj.key.includes("Nattr")
            ) {
              result.push(obj);
            }
          });

          if (isSrc) {
            this.setState(
              {
                srcData: result,
              },
              () => {
                let sModule = modules.filter((item) => item.code == code);
                this.getColumnsData(false, sModule[0].mapping);
              }
            );
          } else {
            this.setState(
              {
                desData: result,
              },
              () => {
                this.mapData();
                this.setState({
                  isLoading: false,
                });
              }
            );
          }
        }
      },
      (error) => {
        console.log("error ", error);
      }
    );
  };

  /** Get mapped attributes from API and updated in srcData array to handle mapping */
  mapData = () => {
    const { srcData, mappedData, desData } = this.state;
    if (mappedData.length > 0 && srcData.length > 0) {
      srcData.map((sData) => {
        let fmData = mappedData.filter(
          (item) => item.SourceColumnName == sData.key
        );

        sData.SourceColumnName =
          fmData.length > 0 ? fmData[0].SourceColumnName : null;

        sData.AttrMappingDtlId =
          fmData.length > 0 ? fmData[0].AttrMappingDtlId : 0;
        sData.MappingColumnName =
          fmData.length > 0 ? fmData[0].MappingColumnName : null;

        let mapFieldsFilter = desData.filter((item) => item.type == sData.type);
        sData.SrcMappingList =
          mapFieldsFilter.length > 0 ? mapFieldsFilter : [];
      });

      this.setState({ srcData });
    } else if (srcData.length > 0) {
      srcData.map((sData) => {
        sData.SourceColumnName = null;
        sData.AttrMappingDtlId = 0;
        sData.MappingColumnName = null;
        let mapFieldsFilter = desData.filter((item) => item.type == sData.type);
        sData.SrcMappingList =
          mapFieldsFilter.length > 0 ? mapFieldsFilter : [];
      });
      this.setState({ srcData });
    }
  };

  onSubmit = () => {
    const { srcData, mappedData, AttrMappingId, selModule, modules } =
      this.state;
    let inputData = [];
    this.setState({
      isSubmit: true,
    });
    // let sMapFilter = srcData.filter(
    //   (item) => item.SourceColumnName != null || item.AttrMappingDtlId > 0
    // );

    srcData.map((smData) => {
      let fields = {
        AttrMappingId: AttrMappingId,
        SourceColumnName: smData.SourceColumnName,
        MappingColumnName: smData.MappingColumnName,
        Active: "Y",
        TenantId: crm.userInfo().pTenantId,
        OrgId: crm.userInfo().pOrgId,
      };

      if (smData.SourceColumnName != null && smData.AttrMappingDtlId > 0) {
        //update
        inputData.push({
          id: "part" + (inputData.length + 1),
          path:
            "/" +
            Services.CHOLA_CUSTOM_MAPPING_DTL +
            "/" +
            smData.AttrMappingDtlId,
          operation: "update",
          payload: fields,
        });
      } else if (
        smData.SourceColumnName != null &&
        smData.AttrMappingDtlId == 0
      ) {
        //create new
        inputData.push({
          id: "part" + (inputData.length + 1),
          path: "/" + Services.CHOLA_CUSTOM_MAPPING_DTL,
          operation: "create",
          payload: fields,
        });
      } else if (
        smData.SourceColumnName == null &&
        smData.AttrMappingDtlId > 0
      ) {
        //delete existing
        inputData.push({
          id: "part" + (inputData.length + 1),
          path:
            "/" +
            Services.CHOLA_CUSTOM_MAPPING_DTL +
            "/" +
            smData.AttrMappingDtlId,
          operation: "delete",
          payload: {},
        });
      }
    });

    if (inputData.length > 0) {
      var formAttr = { parts: inputData };

      let url = encodeURI(envConfig.BASE_API);

      restServices.batchRequest(
        url,
        formAttr,
        (response) => {
          common.snack("S", "Conversion updated successfully");
          this.setState(
            {
              isSubmit: false,
            },
            () => {
              let sModule = modules.filter((item) => item.value == selModule);
              this.getHdrMappingData(sModule[0].api);
            }
          );
        },
        (error) => {
          common.snack("E", error);
        }
      );
    }
  };

  onCancel = () => {
    history.push("/setup/configure");
  };

  render() {
    const {
      isLoading,
      srcData,
      modules,
      selModule,
      isSubmit,
      userPermissions,
    } = this.state;
    return (
      <div>
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="fs-wrap lead-wraper form-settings-content-wrap">
                <div className="filter-wrap">
                  <div className="row justify-content-between">
                    <div className="col-sm-12">
                      <div className="form-row">
                        <div className="col-sm-4">
                          <div className="select-control">
                            <MuiSelect
                              value={selModule}
                              onChange={(e) => this.onModuleSelect(e)}
                            >
                              {modules.map((item, key) => {
                                return (
                                  <MenuItem value={item.value} key={key}>
                                    {item.name + " Mapping"}
                                  </MenuItem>
                                );
                              })}
                            </MuiSelect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col sm={4}>
                    <h2 className="crm-type-title pt-3 mb-4">
                      Conversion Mappings
                    </h2>

                    {srcData.length == 0 && (
                      <p>No custom variable to mapping</p>
                    )}
                  </Col>
                  <Col sm={4}></Col>
                </Row>

                {srcData.map((item, key) => (
                  <Row key={key}>
                    <Col sm={3}>{item.label}</Col>
                    <Col sm={4}>
                      <FormControl className="fs-full-width mb-3">
                        <MuiSelect
                          labelId={"op" + key}
                          value={item.MappingColumnName}
                          onChange={(e) => this.onDefaultOption(e, item)}
                        >
                          <MenuItem value={null}>None</MenuItem>
                          {item.SrcMappingList.map((item, key) => (
                            <MenuItem value={item.key} key={key}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </FormControl>
                    </Col>
                    <Col sm={5}></Col>
                  </Row>
                ))}
                {srcData.length > 0 && (
                  <div className="fs-btns-wrap">
                    <Row className="botBtn">
                      <Col sm={12}>
                        {
                          userPermissions.update && <Button
                            className="saveBtn"
                            type="submit"
                            onClick={() => this.onSubmit()}
                            disabled={isSubmit}
                          >
                            {isSubmit ? "Processing..." : "Update"}
                          </Button>
                        }
                        <Button
                          className="cancelBtn"
                          type="button"
                          onClick={() => this.onCancel()}
                          disabled={isSubmit}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ConversionMapping;
