// Import default packages
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Papa from "papaparse";
import { ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, tools } from "_helpers";
import lookupList from "crm/files/lookupList.json";

const classes = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  table: {
    minWidth: 650,
  },
}));
class BulkImport extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      formFields: {},
      fileName: "",
      isLoading: false,
      isUploading: false,
      uploadProcessed: false,
      excFields: [
        "CreatedBy",
        "CreatedOn",
        "ModifiedBy",
        "ModifiedOn",
        "ListDetailId",
        "TerritoryId",
        "SalespersonId",
        "status",
        "valid",
      ],
      filterLookupKey: [],
      csvData: [],
      failureData: [],
      sCount: 0,
      eCount: 0,
      params,
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { params } = this.state;
    this.setState({ isLoading: true });
    this.getFormData(params.type);
  }

  /**
   * Get Lead dynamic form data
   */
  getFormData = (type) => {
    let EserviceCode =
      type == "lead"
        ? envConfig.ESERVICE_LEAD_CODE
        : envConfig.ESERVICE_ENQUIRY_MEMBER_CODE;
    let filter = `?q=EserviceCode=${EserviceCode}`;
    let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let formFields = JSON.parse(response.items[0].FormFields);
          let fields = formFields.form.fields;
          fields.sort((a, b) => {
            return a.index - b.index;
          });
          this.setState({
            formFields: fields,
            isLoading: false,
          });
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  /**
   * Download csv file
   */
  downloadCSV(fileName, data) {
    var csv = Papa.unparse(data);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, fileName);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
  }

  /**
   * Formatting csv file to download
   */
  downloadFormat = (e) => {
    e.preventDefault();
    const { formFields, excFields, params } = this.state;
    let name = params.type == "lead" ? "lead" : "enquiry_member";
    let csvTitle = {};

    formFields.map((obj) => {
      if (
        !excFields.includes(obj.key) &&
        obj.status == "use" &&
        obj.store == true &&
        obj.disabled == false &&
        obj.visibility == true
      ) {
        csvTitle[obj.label] = "";
      }
    });
    let csvTitleArray = [];
    csvTitleArray.push(csvTitle);
    let fileName = name + "_import_format.csv";
    this.downloadCSV(fileName, csvTitleArray);
  };

  downloadInvalid = () => {
    const { failureData, fileName } = this.state;
    this.downloadCSV(fileName + "_error_records.csv", failureData);
  };

  /**
   * File upload
   */
  handleFileInputChange = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file) {
      this.setState(
        {
          fileName: file.name,
          isUploading: true,
          uploadProcessed: false,
          failureData: [],
          csvData: [],
          filterLookupKey: [],
        },
        () => {
          Papa.parse(file, {
            complete: (result) => {
              let data = result.data;
              this.initUpload(data);
            },
            header: true,
          });
        }
      );
    }
  };

  initUpload = async (data) => {
    this.setState({ csvData: data });
    //remove last empty row if we have more data in csv
    if (data.length > 0) {
      let len = data.length;
      let lastRow = Object.values(data[len - 1]);
      if (this.allEqual(lastRow)) {
        // console.log("no data in last. so we need to pop the array");
        data.pop();
      }
    }

    let valid = this.validateCsvData(data);

    if (valid) {
      //get lookup data
      let LookupResult = await this.getLookupAllKeys(data);

      this.setState(
        {
          filterLookupKey: LookupResult,
        },
        async () => {
          let mappedData = await this.KeyLabelMapping(data);
          let mappedData2 = await this.AllRecordValueMapping(mappedData);
          let mappedData3 = await this.LookupValueMapping(
            mappedData2,
            LookupResult
          );
          this.bulkInsert(mappedData3);
        }
      );
    } else {
      this.setState({
        isUploading: false,
      });
    }
  };

  allEqual(arr) {
    return new Set(arr).size == 1;
  }
  /**
   * Valid the csv data
   */
  validateCsvData = (data) => {
    const { formFields, excFields, params } = this.state;
    let valid = false;
    // Step 1: valid fields if file have data
    if (data.length > 0) {
      let csvKeys = Object.keys(data[0]);
      let csvValues = Object.values(data[0]);

      let dbKeys = [];

      formFields.map((x) => {
        if (
          !excFields.includes(x.key) &&
          x.status == "use" &&
          x.store == true &&
          x.disabled == false &&
          x.visibility == true
        ) {
          dbKeys.push(x.label);
        }
      });

      //validate db and csv fields
      if (JSON.stringify(csvKeys) === JSON.stringify(dbKeys)) {
        const allEqual = this.allEqual(csvValues);
        if (allEqual) {
          common.snack("E", "No data found.Please upload file with data");
          return false;
        } else {
          valid = true;
          data.map(async (row) => {
            let obj = Object.values(row);

            //If any empty row found, ignore the import
            if (obj.join("").replace(/\s/gi, "").length == 0) {
              valid = false;
              common.snack("E", "Empty row found. Please avoid empty row");
              return false;
            } else {
              if (params.type == "lead") {
                //Lead name is mandatory for lead module
                if (
                  (!row["Lead Name"] || row["Lead Name"].trim() == "") &&
                  (!row["Mobile"] || row["Mobile"].trim() == "")
                ) {
                  valid = false;
                  common.snack(
                    "E",
                    "Lead name and MobileNumber is Mandatory.Please provide value"
                  );
                  return false;
                }
              } else if (params.type == "enquiry_member") {
                //First name is mandatory for enquiry member module
                if (!row["First Name"] || row["First Name"].trim() == "") {
                  valid = false;
                  common.snack(
                    "E",
                    "First name is Mandatory.Please provide value"
                  );
                  return false;
                }
              }
            }
          });
        }
      } else {
        common.snack("E", "Please upload valid file with correct fields");
      }
    }
    // Step 2: No data in file
    else if (data.length == 0) {
      common.snack(
        "E",
        "No records found. Please upload file with valid format"
      );
    }
    return valid;
  };

  getLookupAllKeys = async (data) => {
    return new Promise((resolve, reject) => {
      const { formFields, excFields } = this.state;
      let filterLookupKey = [];
      let loop = 0;
      //lookup
      let filteredLookupFields = formFields.filter((data) => {
        return data.type == "lookup" && !excFields.includes(data.key);
      });

      filteredLookupFields.map(async (field) => {
        let columnUniqueValues = [];
        data.map(async (obj) => {
          if (
            obj[field.label] &&
            obj[field.label] != "" &&
            !columnUniqueValues.includes(obj[field.label].toLowerCase().trim())
          ) {
            columnUniqueValues.push(obj[field.label].toLowerCase().trim());
          }
          return obj;
        });

        if (columnUniqueValues.length > 0) {
          filterLookupKey.push({
            key: field.key,
            label: field.label,
            columnUniqueValues: columnUniqueValues,
            lookup: field.attr.lookup,
            items: [],
          });
        } else {
          filterLookupKey.push({
            key: field.key,
            label: field.label,
            columnUniqueValues: [],
            lookup: field.attr.lookup,
            items: [],
          });
        }
        return field;
      });

      filterLookupKey.map((key, index) => {
        if (key.columnUniqueValues.length > 0) {
          let userData = common.authInfo();
          let lookup = lookupList[key.lookup];
          let result = [];

          let url = `${envConfig.BASE_API}${Services[lookup.name]}?q=UPPER(${
            lookup.get
          }) IN${
            "('" + key.columnUniqueValues.join("','") + "')"
          } AND TenantId=${userData.TenantId}`;
          url = encodeURI(url);
          url = url.replace(/&/g, "%26");
          url = url + "&limit=10000&offset=0";

          restServices.getRequest(
            url,
            (response) => {
              if (response) {
                result = response.items ? response.items : [];
                key.items = result;
                loop++;
                if (loop == filterLookupKey.length) {
                  resolve(filterLookupKey);
                }
              }
            },
            (error) => {
              loop++;
            }
          );
        } else {
          key.items = [];
          loop++;
        }
        if (loop == filterLookupKey.length) {
          resolve(filterLookupKey);
        }
      });
    });
  };

  KeyLabelMapping = (data) => {
    const { formFields } = this.state;

    return new Promise((resolve, reject) => {
      let keyMappedData = [];
      data.map(async (rData, index) => {
        let nData = {};
        Object.keys(rData).map(async (cData, index) => {
          let mapField = formFields.filter((item) => item.label == cData);
          nData[mapField[0].key] = rData[cData];
        });
        keyMappedData.push(nData);

        return rData;
      });
      resolve(keyMappedData);
    });
  };

  LookupValueMapping = async (data, filterLookupKey) => {
    const { formFields, excFields } = this.state;

    return new Promise((resolve, reject) => {
      filterLookupKey.map((fData, index) => {
        let fKey = fData.key;
        let fItems = fData.items;
        if (fData.columnUniqueValues.length > 0) {
          let formField = formFields.filter((field) => {
            return field["key"] == fKey && !excFields.includes(field["key"]);
          });

          if (formField && formField.length > 0) {
            let lookup = lookupList[formField[0].attr.lookup];

            data.map((obj) => {
              if (obj[fKey] != undefined && obj[fKey] != null) {
                let match = fItems.filter(
                  (item) =>
                    item[lookup.get].toLowerCase().trim() ==
                    obj[fKey].toLowerCase().trim()
                );
                if (match.length > 0) {
                  obj[fKey] = match[0][lookup.set];
                } else {
                  obj["status"] =
                    obj["status"] + "Invalid - " + fData.label + ".";
                  obj["valid"] = false;
                }
              }
            });
          }
        }
        //return fData;
      });
      resolve(data);
    });
  };

  AllRecordValueMapping = async (data) => {
    const { formFields } = this.state;

    return new Promise((resolve, reject) => {
      data.map(async (rData, index) => {
        rData["valid"] = true;
        rData["status"] = "";

        Object.keys(rData).map(async (cData, index) => {
          let filteredFields = await formFields.filter((data) => {
            return data.key == cData;
          });
          if (filteredFields.length > 0) {
            let filterField = filteredFields[0];
            if (rData[cData] == "") {
              rData[cData] = null;
            } else {
              if (filterField.type == "pickList") {
                let options = filterField.attr.option;
                if (
                  options.length > 0 &&
                  rData[cData] != "" &&
                  rData[cData] != null
                ) {
                  const index = await options.findIndex((element) => {
                    return (
                      element.toLowerCase().trim() ==
                      rData[cData].toLowerCase().trim()
                    );
                  });
                  if (index > -1) {
                    rData[cData] =
                      filterField["type"] == "number"
                        ? parseInt(options[index])
                        : options[index];
                  } else {
                    rData["valid"] = false;
                    rData["status"] =
                      rData["status"] + "Invalid -" + filterField.label + ".";
                  }
                }
              } else if (filterField["type"] == "email") {
                if (tools.ValidateEmail(rData[cData])) {
                  rData[cData] = rData[cData];
                } else {
                  rData["valid"] = false;
                  rData["status"] =
                    rData["status"] +
                    "Invalid email -" +
                    filterField.label +
                    ".";
                }
              } else if (filterField["type"] == "number") {
                if (rData[cData] != "") {
                  if (isNaN(rData[cData])) {
                    rData["valid"] = false;
                    rData["status"] =
                      rData["status"] +
                      "Invalid number " +
                      filterField.label +
                      ".";
                  } else {
                    let value = parseInt(rData[cData]);
                    rData[cData] = value;
                  }
                }
              } else if (filterField["type"] == "decimal") {
                if (rData[cData] != "") {
                  if (isNaN(rData[cData])) {
                    rData["valid"] = false;
                    rData["status"] =
                      rData["status"] +
                      "Invalid decimal " +
                      filterField.label +
                      ".";
                  } else {
                    let value = parseFloat(rData[cData]);
                    rData[cData] = value;
                  }
                }
              } else if (filterField["type"] == "currency") {
                if (rData[cData] && rData[cData] != "") {
                  let cAmount = Number(rData[cData].replace(/,/g, ""));
                  if (isNaN(cAmount)) {
                    rData["valid"] = false;
                    rData["status"] =
                      rData["status"] +
                      "Invalid currency " +
                      filterField.label +
                      ".";
                  } else {
                    rData[cData] = cAmount;
                  }
                }
              } else if (filterField["type"] == "phone") {
                let phoneInput = rData[cData];
                if (phoneInput.length > 0) {
                  if (phoneInput.charAt(0) != "+") {
                    rData["valid"] = false;
                    rData["status"] =
                      rData["status"] +
                      "Invalid - " +
                      filterField.label +
                      ".Must number start with + countrycode" +
                      ".";
                  }
                }
              } else if (
                filterField["type"] == "date" ||
                filterField["type"] == "dateTime"
              ) {
                let date = rData[cData] ? new Date(rData[cData]) : null;
                let dVal = date
                  ? common.formatDate(date, "YYYY-MM-DDTHH:mm:ssZ")
                  : null;

                if (dVal == "Invalid date") {
                  rData["valid"] = false;
                  rData["status"] =
                    rData["status"] + "Invalid -" + filterField.label + ".";
                } else {
                  rData[cData] = dVal;
                }
              } else if (filterField["type"] == "checkbox") {
                let valid = ["y", "yes", "n", "no"];
                if (valid.includes(rData[cData].toLowerCase().trim())) {
                  let value =
                    rData[cData].toLowerCase().trim() == "y" ||
                    rData[cData].toLowerCase().trim() == "yes"
                      ? "Y"
                      : "N";
                  rData[cData] = value;
                } else {
                  rData["valid"] = false;
                  rData["status"] =
                    rData["status"] +
                    "Invalid " +
                    filterField.label +
                    "- value must be Y or N.";
                }
              }
            }
          }
          return cData;
        });

        return rData;
      });

      resolve(data);
    });
  };

  bulkInsert = (data) => {
    let success = data.filter((item) => item.valid == true);
    this.createLeads(success, data);
  };
  /**
   * Add leads in DB
   * @param {*} inputData
   */
  createLeads = async (success, failure) => {
    const { csvData, fileName, params } = this.state;
    let EserviceCode =
      params.type == "lead" ? Services.CRM_LEADS : Services.CRM_LIST_DTLS;
    let payload = {};
    let failureData = [];

    if (failure.length > 0) {
      failure.map((fData, idx) => {
        if (fData.valid == false) {
          csvData[idx]["status"] = fData.status;
          failureData.push(csvData[idx]);
        }
      });
    }

    success.map((obj, index) => {
      let gIdx = Math.floor(index / 500);
      let arr = [];
      if (payload.hasOwnProperty(gIdx)) {
        arr = payload[gIdx];
      } else {
        payload[gIdx] = [];
      }

      let dataObj = Object.assign({}, obj);
      let userData = common.authInfo();

      if (params.type == "lead") {
        dataObj.SalespersonId = userData.EmpId;
        dataObj.TerritoryId = userData.territory[0];
      } else {
        dataObj.ListId = params.listId;
      }

      dataObj.TenantId = parseInt(userData.TenantId);
      dataObj.OrgId = parseInt(userData.DefaultOrgId);

      delete dataObj.status;
      delete dataObj.valid;

      arr.push({
        id: "part" + (index + 1),
        path: "/" + EserviceCode,
        operation: "create",
        payload: dataObj,
      });

      payload[gIdx] = arr;
    });

    if (Object.keys(payload).length > 0) {
      let uploadResult = await this.importData(payload);

      console.log("uploadResult", uploadResult);

      if (uploadResult.length == Object.keys(payload).length) {
        common.snack(
          "S",
          params.type == "lead"
            ? "Leads are successfully uploaded"
            : "Enquiry Members are successfully uploaded"
        );
        this.setState({
          isUploading: false,
          uploadProcessed: true,
          sCount: success.length,
          eCount: failureData.length,
          failureData,
        });
        if (failureData.length > 0) {
          this.downloadCSV(fileName + "_error_records.csv", failureData);
        }
      }
    } else if (failureData.length > 0) {
      this.setState({
        isUploading: false,
        uploadProcessed: true,
        sCount: success.length,
        eCount: failureData.length,
        failureData,
      });
      this.downloadCSV(fileName + "_error_records.csv", failureData);
    }

    // if (inputData.length > 0) {
    //   var formAttr = { parts: inputData };
    //   let url = encodeURI(envConfig.BASE_API);

    //   restServices.batchRequest(
    //     url,
    //     formAttr,
    //     (response) => {
    //       if (response != null) {
    //         common.snack(
    //           "S",
    //           params.type == "lead"
    //             ? "Leads are successfully uploaded"
    //             : "Enquiry Members are successfully uploaded"
    //         );
    //         this.setState({
    //           isUploading: false,
    //           uploadProcessed: true,
    //           sCount: inputData.length,
    //           eCount: failureData.length,
    //           failureData,
    //         });
    //         if (failureData.length > 0) {
    //           this.downloadCSV(fileName + "_error_records.csv", failureData);
    //         }
    //       }
    //     },
    //     (error) => {
    //       common.snack("E", error.toString());
    //       this.setState({
    //         isUploading: false,
    //         uploadProcessed: true,
    //         sCount: inputData.length,
    //         eCount: failureData.length,
    //         failureData,
    //       });
    //     }
    //   );
    // } else if (failureData.length > 0) {
    //   this.setState({
    //     isUploading: false,
    //     uploadProcessed: true,
    //     sCount: inputData.length,
    //     eCount: failureData.length,
    //     failureData,
    //   });
    //   this.downloadCSV(fileName + "_error_records.csv", failureData);
    // }
  };

  importData = async (payload) => {
    let status = [];

    return await new Promise((resolve, reject) => {
      Object.keys(payload).map(function (pData, idx) {
        var formAttr = { parts: payload[pData] };
        let url = encodeURI(envConfig.BASE_API);
        status.push({ status: false });
        restServices.batchRequest(
          url,
          formAttr,
          (response) => {
            if (response != null) {
              status.push({ status: false });
              resolve(status);
            }
          },
          (error) => {
            status.push({ status: false });
          }
        );

        return pData;
      });
      resolve(status);
    });
  };

  render() {
    const {
      isLoading,
      isUploading,
      uploadProcessed,
      csvData,
      sCount,
      eCount,
      params,
    } = this.state;

    let tot = csvData.length;
    let sucess = Math.round((sCount / tot) * 100);
    let failure = 100 - sucess;
    return (
      <div className="lead-wraper">
        <h5>
          {params.type == "lead"
            ? "Lead Bulk Import"
            : "Enquiry Member Bulk Import"}
        </h5>
        {!isUploading && (
          <div className="row justify-content-between mt-5">
            <div className="col-3"></div>
            <div className="col-2">
              <div className={classes.root}>
                <button
                  type="button"
                  className="btn btn-import"
                  onClick={(e) => this.downloadFormat(e)}
                  disabled={isLoading}
                >
                  Download Format
                </button>
              </div>
            </div>
            <div className="col-3">
              <input
                accept=".csv"
                className="hideColumn"
                id="upload_new"
                onChange={(e) => this.handleFileInputChange(e)}
                multiple={false}
                type="file"
                disabled={isLoading || isUploading}
              />
              <label htmlFor="upload_new" className="lbl-import">
                <Button
                  variant="contained"
                  color="primary"
                  className="btn-upload"
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
            <div className="col-4"></div>
          </div>
        )}
        {isUploading && (
          <div className="row justify-content-between">
            <div className="col-12 progress-sec">
              <ProgressBar>
                <ProgressBar striped variant="info" animated now={100} />
              </ProgressBar>
              <h3 className="progress-txt">Uploading...Please wait</h3>
            </div>
          </div>
        )}
        {uploadProcessed && (
          <div className="row justify-content-between">
            <div className="col-12 progress-sec">
              <ProgressBar>
                <ProgressBar
                  striped
                  variant="success"
                  now={sucess}
                  key={1}
                  label="success"
                />
                <ProgressBar
                  striped
                  variant="danger"
                  now={failure}
                  key={2}
                  label="failure"
                />
              </ProgressBar>
              <p className="progress-info-text">Uploaded Information</p>
              <p className="progress-indicator-container">
                <label className="success success-indicator indicator-label">
                  Success <b>{sucess} %</b>
                </label>
                <label className="danger danger-indicator indicator-label">
                  Failure <b>{failure} %</b>
                </label>
              </p>
              {eCount > 0 && (
                <button
                  type="button"
                  className="btn btn-primary text-center"
                  onClick={() => this.downloadInvalid()}
                >
                  Download Failure Records
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default BulkImport;
