import React, { useEffect, useState, Fragment } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
// material
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// api
import * as Services from "_config/api";
import { envConfig, eduTechConfig } from "_config/config";
import { common, restServices, eduTechSerivces, history } from "_helpers";
import { FormFieldAuto } from "pages/_components/FormFields";
// pages
import Loader from "_components/_loader/Loader";
// css
import "./includes/DynamicForm.css";
import DeleteIcon from "img/delete.svg";

const selectDefault = { label: "", value: "" };

function EduTechDeal(props) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [status, setStatus] = useState("process");
  const [imgPreview, setImgPreview] = useState(false);
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [fields, setFields] = useState({}); //crm deal response
  const [fileA, setFileA] = useState([]);
  const [fileB, setFileB] = useState([]);
  const [fileC, setFileC] = useState([]);
  const [del, setDel] = useState([]);
  const [dealStatus, setDealStatus] = useState(eduTechConfig.DealStatus);

  // effect
  useEffect(() => {
    if (props.data.value1 == "") {
      history.push("/crm/deal");
    } else {
      setFields(props.data.dealData);
      setCRMDealData(props.data.dealData);
    }
  }, []);

  const setCRMDealData = (data) => {
    let newFields = common.reParse(data);
    newFields.Cattr7 = data.Cattr7 || "";
    newFields.Cattr6 =
      data.ApprStatus == "Conditionally Accepted" && data.Cattr6
        ? common.getMissingFields(data.Cattr6, false)
        : ""; //Missing Fields
    newFields.SalesPersonId = {
      label: data.SalesPersonIdDesc,
      value: data.SalesPersonId,
    }; //sales person
    setDealStatus(common.enableDealStatus(data.ApprStatus));
    newFields.ApprStatus = { label: data.ApprStatus, value: data.ApprStatus }; //deal status

    setFields(newFields);
    setStatus("completed");
  };

  const getResposiblePersonEmail = (empId) => {
    let url = encodeURI(
      envConfig.BASE_API +
        Services.CRM_PAY_EMPLOYEE +
        "/" +
        empId +
        "?fields=Email"
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          // setResPersonEMail(response.Email);
          let newFields = common.reParse(fields);
          newFields.responsiblePersonEmail = response.Email;
          setFields(newFields);
        }
      },
      (error) => {}
    );
  };

  // Form field auto handler
  const onSelect = (value, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = value || selectDefault;
    newFields.isUpdate = true;
    setFields(newFields);
    if (name == "SalesPersonId" && value) {
      getResposiblePersonEmail(value.value);
    }
    if (value && value.value == "Conditionally Accepted") {
    } else if (value && value.value == "Unconditionally Accepted") {
    }
  };

  // Select handler
  const onSelectHandler = (data, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = data;
    setFields(newFields);
  };

  //textarea, text, , datepicker handler
  const onChange = (e) => {
    let newFields = common.reParse(fields);
    if (
      e &&
      e.target &&
      (e.target.type == "text" || e.target.type == "textarea")
    ) {
      const { name, value } = e.target;
      newFields[name] = value;
    } else {
      newFields["Dattr1"] = e;
    }
    newFields.isUpdate = true;
    setFields(newFields);
  };

  // file handler
  const onFileChangeA = (e) => {
    const files = e.target.files;
    setFileA((prev) => {
      let nData = [...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }
      return result;
    });
  };

  const onFileChangeB = (e) => {
    const files = e.target.files;
    setFileB((prev) => {
      let nData = [...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }

      return result;
    });
  };

  const onFileChangeC = (e) => {
    const files = e.target.files;
    setFileC((prev) => {
      let nData = [...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }

      return result;
    });
  };

  const delImage = (e, src) => {
    e.preventDefault();
    let newFields = common.reParse(fields);
    if (src == "fileA") {
      setFileA([]);
      newFields.Cattr8 = "";
    } else if (src == "fileB") {
      setFileB([]);
      newFields.Cattr12 = "";
    } else if (src == "fileC") {
      setFileC([]);
      newFields.Cattr21 = "";
    }
    setFields(newFields);
  };

  const uploadFile = async (idx) => {
    var FormDataObj = new FormData();
    if (idx == "fileA") {
      fileA.forEach((i, k) => {
        FormDataObj.append("file", i);
      });
    } else if (idx == "fileB") {
      fileB.forEach((i, k) => {
        FormDataObj.append("file", i);
      });
    } else if (idx == "fileC") {
      fileC.forEach((i, k) => {
        FormDataObj.append("file", i);
      });
    }

    let data = {
      url: "common_upload_direct",
      method: "POST",
      auth: "none",
      body: FormDataObj,
      ssr: true,
      cType: 6,
    };
    let resultData = await eduTechSerivces.call(data);
    return resultData;
  };

  const previewImage = (e, file) => {
    e.preventDefault();
    setImgPreview(true);
    setImgPreviewSrc(file);
  };

  const handleClose = () => {
    setImgPreview(false);
    setImgPreviewSrc("");
  };

  //go back to list page
  const onCancel = () => {
    history.push("/crm/deal");
  };

  const update = async () => {
    setIsSubmit(true);
    let aResult = {};
    let bResult = {};
    let cResult = {};
    let error = "";
    // upload 1
    if (fileA?.length) {
      aResult = await uploadFile("fileA");
      if (aResult.status !== 200) {
        error = aResult.data.error;
      }
    }
    // upload 2
    if (!error && fileB?.length) {
      bResult = await uploadFile("fileB");
      if (bResult.status !== 200) {
        error = bResult.data.error;
      }
    }

    // upload 3
    if (!error && fileC?.length) {
      cResult = await uploadFile("fileC");
      if (cResult.status !== 200) {
        error = cResult.data.error;
      }
    }
    if (error) {
      common.snack("E", error);
      setIsSubmit(false);
    } else {
      let inputs = await formInputs(aResult, bResult, cResult);
      if (inputs && inputs.dealData && inputs.appData) {
        let url =
          envConfig.BASE_API +
          Services.CRM_DEAL_OPPORTUNITY +
          "/" +
          props.data.value1;

        restServices.patchRequest(
          url,
          inputs.dealData,
          async (response) => {
            window.scrollTo(0, 0);
            setIsSubmit(false);
            if (Object.keys(inputs.appData).length > 0) {
              await eduTechSerivces.updateApplicationInfo(
                fields.Cattr2,
                inputs.appData
              );
            }
            if (typeof props.onSave !== "undefined") {
              props.onSave(response);
            }
            common.snack("S", "Successfully Updated");
            setDealStatus(common.enableDealStatus(fields.ApprStatus.value));
          },
          (error) => {
            setIsSubmit(false);
          }
        );
      }
    }
  };

  const formInputs = async (aResult, bResult, cResult) => {
    let appData = {};
    let uFields = {};

    appData.dealId = props.data.value1;
    //Responsible person
    if (fields.SalesPersonId.value) {
      uFields.SalesPersonId = fields.SalesPersonId.value;
      appData.responsiblePersonEmail = fields.responsiblePersonEmail;
    }

    //Deal status
    if (fields.ApprStatus.value) uFields.ApprStatus = fields.ApprStatus.value;
    if (fields.ApprStatus.value == "G2G/Submitted") {
      appData.status = "submitted_to_university";
    }
    if (fields.ApprStatus.value == "Conditionally Accepted") {
      if (
        ((fields.Cattr7 && fields.Cattr6) ||
          (fields.Cattr5 && fields.Dattr1)) &&
        (aResult.data?.link?.length || fields.Cattr8)
      ) {
        let url = aResult.data?.link[0] || fields.Cattr8;
        appData.status = "conditional_offer";
        if (fields.Cattr7 && fields.Cattr6) {
          let mfields = common.getMissingObject(fields.Cattr6);
          appData.cAttr1 = fields.Cattr7; //missing desc
          appData.jAttr1 = mfields; // missing fields

          let mfArray = fields.Cattr6.map((a) => a.label);
          uFields.Cattr6 = mfArray.join(",");
          uFields.Cattr7 = fields.Cattr7;
        } else if (fields.Cattr5 && fields.Dattr1) {
          appData.cAttr2 = fields.Cattr5; //interview loc
          appData.dAttr1 = common.formatDate(
            fields.Dattr1,
            "YYYY-MM-DDTHH:mm:ssZ"
          );

          uFields.Cattr5 = fields.Cattr5;
          uFields.Dattr1 = common.formatDate(
            fields.Dattr1,
            "YYYY-MM-DDTHH:mm:ssZ"
          );
        }

        appData.cAttr3 = url; //offerletter url

        uFields.Cattr8 = url;
      } else {
        common.snack("E", "Please fill the conditional offer fields");
        setIsSubmit(false);
        return null;
      }
    } else if (fields.ApprStatus.value == "Unconditionally Accepted") {
      if (bResult.data?.link?.length) {
        let url = bResult.data?.link[0];
        appData.status = "unconditional_offer";
        appData.cAttr3 = url; //offerletter url

        uFields.Cattr12 = url;
      } else {
        common.snack("E", "Please upload unconditional offer letter");
        setIsSubmit(false);
        return null;
      }
    } else if (fields.ApprStatus.value == "Ready for Payment") {
      appData.status = "tuition_fees_process";
    } else if (fields.ApprStatus.value == "Acceptance Statement") {
      if (cResult.data?.link?.length) {
        let url = cResult.data?.link[0] || fields.Cattr21;
        appData.status = "accepted";
        appData.cAttr3 = url; //acceptance url

        uFields.Cattr21 = url;
      } else {
        common.snack("E", "Please upload acceptance letter");
        setIsSubmit(false);
        return null;
      }
    } else if (fields.ApprStatus.value == "Enrolled") {
      appData.status = "enrolled";
      appData.description = "Course Enrolled Successfully";
    } else if (fields.ApprStatus.value == "Visa Applied") {
      appData.status = "visa_applied";
      appData.description = "";
    } else if (fields.ApprStatus.value == "Visa Refused") {
      appData.status = "visa_refused";
      appData.description = "";
    } else if (fields.ApprStatus.value == "Visa Approved") {
      appData.status = "visa_approved";
      appData.description = "";
    } else if (fields.ApprStatus.value == "Enrolled") {
      appData.status = "enrolled";
      appData.description = "Course Enrolled Successfully";
    } else if (fields.ApprStatus.value == "Application Rejected") {
      appData.status = "rejected";
      appData.description = "Application Rejected";
    }

    return {
      dealData: uFields,
      appData: appData,
    };
  };

  const renderAttachment = (docs) => {
    return (
      <div className="mt-1">
        {docs.map((image, idx) => {
          return ["jpg", "jpeg", "png"].includes(image.split(".").pop()) ? (
            <img
              key={idx}
              src={common.loadEduImage(image)}
              className="eduTechImg"
              onClick={(e) => previewImage(e, image)}
            />
          ) : (
            <div className="attach-link" key={idx}>
              <a href={common.loadEduImage(image)} download>
                <img src={common.loadImg("attachment.png")} />
                {image.split("/").pop()}
              </a>
            </div>
          );
        })}
      </div>
    );
  };
  let course = fields.Cattr22 ? fields.Cattr22.split(",") : [];
  // render
  return (
    <Fragment>
      {status == "process" ? (
        <Loader />
      ) : (
        status == "completed" && (
          <Fragment>
            <div className="mb-100">
              <Row className="mb-3">
                <Col sm={12} className="formWhiteBg">
                  <div className="formHead">
                    <h2>Deal Information</h2>
                  </div>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>{" "}
                        {fields.Cattr10 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        {fields.Cattr11 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        {fields.Email || "-"}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Mobile</Form.Label>
                        {fields.MobileNumber || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Application ID</Form.Label>{" "}
                        {fields.Cattr2 || "-"}
                      </Form.Group>
                    </Col>

                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Agent Email</Form.Label>{" "}
                        {fields.Cattr1 && fields.Cattr4
                          ? fields.Cattr1 + " & " + fields.Cattr4
                          : "-"}
                      </Form.Group>
                    </Col>
                  </Row>
                  {fields.Cattr22 && (
                    <Row>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>Course</Form.Label>
                          {course[0] || "-"}
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>University</Form.Label>
                          {course[1] || "-"}
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>Intake</Form.Label>
                          {course[2]} {course[3] || "-"}
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>

              <Row className="mb-3">
                <Col sm={12} className="formWhiteBg">
                  <div className="formHead">
                    <h2>Application Status</h2>
                  </div>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Responsible Person</Form.Label>
                        <FormFieldAuto
                          isClearable={false}
                          isRender={true}
                          name="SalesPersonId"
                          type="FindEmployee"
                          value={fields.SalesPersonId}
                          onSelect={onSelect}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Deal Status</Form.Label>
                        <Select
                          onChange={(data) =>
                            onSelectHandler(data, "ApprStatus")
                          }
                          options={dealStatus}
                          isOptionDisabled={(option) => option.disabled}
                          defaultValue={fields["ApprStatus"]}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {fields.ApprStatus.value == "Conditionally Accepted" && (
                    <>
                      <Row className="mb-3">
                        <Col className="sub-head-title">
                          <h2>Conditional Offer</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Missing Documents</Form.Label>
                            <Select
                              name="Cattr6"
                              defaultValue={fields.Cattr6}
                              isMulti={true}
                              onChange={(data) =>
                                onSelectHandler(data, "Cattr6")
                              }
                              options={eduTechConfig.docMissingFields}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Interview Date</Form.Label>
                            <DatePicker
                              isClearable={true}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              showTimeSelect
                              timeIntervals={1}
                              timeCaption="Time"
                              dateFormat="MMMM d, yyyy hh:mm aa"
                              placeholderText="Select Date and Time"
                              selected={
                                fields.Dattr1
                                  ? moment(fields.Dattr1).toDate()
                                  : null
                              }
                              onChange={(date) => onChange(date)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              Missing Documents Description
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              name="Cattr7"
                              rows={3}
                              value={fields.Cattr7}
                              onChange={(e) => onChange(e)}
                            />
                          </Form.Group>
                        </Col>

                        <Col sm={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Interview Location</Form.Label>
                            <Form.Control
                              type="text"
                              name="Cattr5"
                              onChange={(e) => onChange(e)}
                              value={fields.Cattr5}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Offer Letter</Form.Label>
                            <div class="fileUpload saveBtn btn btn-primary">
                              <label class="upload">
                                <input
                                  type="file"
                                  id="fileA"
                                  name="offerLetter1"
                                  accept=".pdf,.jpg,.png,.doc"
                                  value=""
                                  multiple={false}
                                  onChange={(e) => onFileChangeA(e)}
                                />
                                Upload
                              </label>
                            </div>
                            <br />
                            {fileA.map((i, k) => (
                              <p className="file-list">
                                {i.name}
                                <img
                                  src={DeleteIcon}
                                  className="del-icon"
                                  alt="Delete"
                                  onClick={(e) => delImage(e, "fileA")}
                                />
                              </p>
                            ))}
                            {fields.Cattr8 && renderAttachment([fields.Cattr8])}
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}

                  {fields.ApprStatus.value == "Unconditionally Accepted" && (
                    <>
                      <Row className="mb-3">
                        <Col className="sub-head-title">
                          <h2>Unconditional Offer</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Offer Letter</Form.Label>
                            <div class="fileUpload saveBtn btn btn-primary">
                              <label class="upload">
                                <input
                                  type="file"
                                  id="fileB"
                                  name="offerLetter2"
                                  placeholder="Upload"
                                  accept=".pdf,.jpg,.png,.doc"
                                  value=""
                                  multiple={false}
                                  onChange={(e) => onFileChangeB(e)}
                                />
                                Upload
                              </label>
                            </div>

                            <br />
                            {fileB.map((i, k) => (
                              <p>
                                {i.name}
                                <img
                                  src={DeleteIcon}
                                  className="del-icon"
                                  alt="Delete"
                                  onClick={(e) => delImage(e, "fileB")}
                                />
                              </p>
                            ))}
                            {fields.Cattr12 &&
                              renderAttachment([fields.Cattr12])}
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}

                  {fields.ApprStatus.value == "Acceptance Statement" && (
                    <>
                      <Row>
                        <Col className="sub-head-title">
                          <h2>Acceptance Letter</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Acceptance Letter Upload</Form.Label>
                            <div class="fileUpload saveBtn btn btn-primary">
                              <label class="upload">
                                <input
                                  type="file"
                                  id="fileC"
                                  accept=".pdf,.jpg,.png,.doc"
                                  name="acceptanceLetter"
                                  multiple={false}
                                  value=""
                                  placeholder="Upload"
                                  onChange={(e) => onFileChangeC(e)}
                                />
                                Upload
                              </label>
                            </div>

                            {fileC.map((i, k) => (
                              <p>
                                {i.name}
                                <img
                                  src={DeleteIcon}
                                  className="del-icon"
                                  alt="Delete"
                                  onClick={(e) => delImage(e, "fileC")}
                                />
                              </p>
                            ))}
                            <br />
                            {fields.Cattr21 &&
                              renderAttachment([fields.Cattr21])}
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>

              {(fields.Cattr8 || fields.Cattr12 || fields.Cattr21) &&
                ![
                  "Conditionally Accepted",
                  "Unconditionally Accepted",
                ].includes(fields.ApprStatus.value) && (
                  <Row className="mb-3">
                    <Col sm={12} className="formWhiteBg">
                      <div className="formHead">
                        <h2>Offer</h2>
                      </div>
                      <Row>
                        {fields.Cattr8 && (
                          <Col sm={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>Conditional Offer Letter</Form.Label>
                              {fields.Cattr8 &&
                                renderAttachment([fields.Cattr8])}
                            </Form.Group>
                          </Col>
                        )}
                        {fields.Cattr12 && (
                          <Col sm={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Unconditional Offer Letter
                              </Form.Label>
                              {fields.Cattr12 &&
                                renderAttachment([fields.Cattr12])}
                            </Form.Group>
                          </Col>
                        )}
                        {fields.Cattr21 && (
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Acceptance Statement</Form.Label>
                              {fields.Cattr21 &&
                                renderAttachment([fields.Cattr21])}
                            </Form.Group>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                )}

              {fields.Cattr23 && (
                <Row className="mb-3">
                  <Col sm={12} className="formWhiteBg">
                    <div className="formHead">
                      <h2>Payment INFORMATION</h2>
                    </div>
                    <Row>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>Payment Mode</Form.Label>{" "}
                          {fields.Cattr23 || "-"}
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>Payment Date</Form.Label>{" "}
                          {fields.Dattr2
                            ? common.formatDate(
                                fields.Dattr2,
                                "DD-MM-YYYY HH:MM a"
                              )
                            : ""}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Payment Documents</Form.Label>
                          {fields.Cattr13
                            ? renderAttachment([fields.Cattr13])
                            : "-"}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              {fields.Cattr14 ||
                (fields.Cattr25 && (
                  <Row className="mb-3">
                    <Col sm={12} className="formWhiteBg">
                      <div className="formHead">
                        <h2>Visa Information</h2>
                      </div>
                      <Row>
                        <Col sm={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>Visa Documents</Form.Label>
                            {fields.Cattr25
                              ? renderAttachment([fields.Cattr25])
                              : "-"}
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>Visa - Connected Agent</Form.Label>{" "}
                            {fields.Cattr14 || "-"}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
            </div>
            <div className="leadfooter-btn">
              <Row className={!props.quick ? "botBtn" : ""}>
                <Col sm={12}>
                  <Button
                    className="saveBtn"
                    type="submit"
                    onClick={() => update()}
                    disabled={false}
                  >
                    {isSubmit ? "Processing..." : "Update"}
                  </Button>
                  <Button
                    className="cancelBtn"
                    type="button"
                    onClick={() => onCancel()}
                    disabled={isSubmit}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>

            {imgPreview && (
              <Dialog
                open={imgPreview}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="false"
                minWidth="false"
              >
                <DialogTitle
                  id="responsive-dialog-title"
                  className="popup-title"
                >
                  <a href={common.loadEduImage(imgPreviewSrc)} download>
                    Download
                  </a>{" "}
                  {"  "}
                  <Link to="#" onClick={handleClose}>
                    Close
                  </Link>
                </DialogTitle>
                <DialogContent>
                  <div className="popup-form-wrap imgDlgContent">
                    <div className="list-row text-center">
                      <img
                        src={common.loadEduImage(imgPreviewSrc) || null}
                        alt="image"
                      />
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            )}

            {/* <Dialog
              open={isDialog === "delete"}
              onClose={() => deleteNo()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Confirmation"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete the file?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => deleteNo()} color="primary">
                  No
                </Button>
                <Button onClick={() => deleteYes()} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog> */}
          </Fragment>
        )
      )}
    </Fragment>
  );
}

export default EduTechDeal;
