const AuthURL = "https://api.osmocrm.com/cholaauthservices/";
const BaseURL = "https://api.osmocrm.com/cholacrmrestservices/api/v1/";
const AuthRestURL = "https://api.osmocrm.com/cholaauthservices/rest/v1/";
const ServletURL = "https://api.osmocrm.com/cholacrmrestservices/cholapic";
const ImgServletURL =
  "https://api.osmocrm.com/cholacrmrestservices/cholaimgservlet";
const FileURL = "https://api.osmocrm.com/cholacrmrestservices/";

const DownloadURL =
  "https://api.osmocrm.com/cholacrmrestservices/cholapic?imagePath=";
const uploadURL =
  "https://api.osmocrm.com/cholacrmrestservices/cholauploadservlet";
const EduTechURL =
  "http://ec2-34-236-143-5.compute-1.amazonaws.com:3000/api/service/crm";
const EduTechComURL =
  "http://ec2-34-236-143-5.compute-1.amazonaws.com:3000/api/";
const EduTechS3URL = "https://edutech22.s3.amazonaws.com/";
const EdCrmAuthToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJPcmdJZCI6IjU3NCIsInN1YiI6ImRldi5lZHV0ZWNoMjAyMkBnbWFpbC5jb20iLCJUZW5hbnRJZCI6Ijc1IiwiaXNzIjoiQ0hPTEFfUkVTVF9BUEkiLCJleHAiOjE2NjA0ODkxODIsImlhdCI6MTY1ODQxNTU4MiwicHJuIjoiZGV2LmVkdXRlY2gyMDIyQGdtYWlsLmNvbSJ9.DSehUYLl2Ojo10mPxb0iJPjJQKeEFF2OvjQHpr1B2J7oN-rY_Ib0dCcEFOQRAS1l7y7cPUVFnPVQO8A9cW4r9g";
// const EduTechS3URL = "https://noveltycard2020.s3.ap-south-1.amazonaws.com/";

export const envConfig = {
  EDUTECH_S3_URL: `${EduTechS3URL}`,
  EDUTECH_URL: `${EduTechURL}`,
  ED_CRM_TOKEN: `${EdCrmAuthToken}`,
  ETECH_COMM_URL: `${EduTechComURL}`,
  UPLOAD_URL: `${uploadURL}`,
  DOWNLOAD_URL: `${DownloadURL}`,
  FILE_URL: `${FileURL}`,
  AUTH_API: `${AuthURL}`,
  BASE_API: `${BaseURL}`,
  AUTH_REST_URL: `${AuthRestURL}`,
  IMG_SERVLET_URL: `${ImgServletURL}`,
  SERVLET_URL: `${ServletURL}`,
  PARENT_TENANT_ID: 33,
  LIST_TRANS_TYPE_ID: "CRM_LISTS",
  LEAD_TRANS_TYPE_ID: "CRM_LEADS",
  DEAL_TRANS_TYPE_ID: "CRM_OPPORTUNITY",
  CAMPAIGN_TRANS_TYPE_ID: "CRM_CAMPAIGNS",
  QUOTE_TRANS_TYPE_ID: "CUSTOMER_QUOTATION",
  SALESORDER_TRANS_TYPE_ID: "CUSTOMER_ORDER",
  INVOICE_TRANS_TYPE_ID: "SALES_INVOICE",
  ESERVICE_LIST_CODE: "CRM_LISTS",
  ESERVICE_ENQUIRY_CODE: "CRM_LISTS",
  ESERVICE_LEAD_CODE: "CRM_LEADS",
  ESERVICE_DEAL_CODE: "CRM_OPPORTUNITY",
  ESERVICE_CAMPAIGN_CODE: "CRM_CAMPAIGNS",
  ESERVICE_QUOTE_CODE: "CUSTOMER_QUOTATION",
  ESERVICE_SALESORDER_CODE: "CUSTOMER_ORDER",
  ESERVICE_INVOICE_CODE: "SALES_INVOICE",
  ESERVICE_ENQUIRY_MEMBER_CODE: "CRM_LISTS_MEMBER",
  ESERVICE_CONTACTS_CODE: "CRM_CONTACTS",
  ESERVICE_CALLOGS_CODE: "CRM_CALLLOG",
  ESERVICE_MEETING_CODE: "CRM_MEETING",
  DEFAULT_ROLE_GROUP: "Osmo Default",
  SENDER_EMAIL: "vimaladevi@atommedialabs.com",
  SITE_URL: "http://osmocrm.com",
  allPermissions: [
    { label: "All", value: "1" },
    { label: "Access Denied", value: "2" },
    { label: "Personal", value: "3" },
    { label: "Personal & department", value: "4" },
    { label: "Personal, department & subdepartment", value: "5" },
  ],
  permissions: [
    { label: "All", value: "1" },
    { label: "Access Denied", value: "2" },
  ],
  MergeFieldModule: [
    { label: "Leads", value: "CholaCrmLeads" },
    { label: "Deals", value: "CholaCrmOpportunity" },
  ],
  WorkingStatus: "ACTIVE",
};

export const eduTechConfig = {
  LeadStatus: [
    { label: "Payment Pending", value: "Payment Pending", disabled: true },
    { label: "Processing", value: "Processing", disabled: true },
    // { label: "Missing Info", value: "Missing Info", disabled: true },
    // { label: "Received Missing Info", value: "Received Missing Info", disabled: true },
    // { label: "Course Suggested", value: "Course Suggested", disabled: true },
    { label: "Complete Lead", value: "Complete Lead", disabled: true },
    { label: "Application Rejected", value: "Application Rejected", disabled: true },
    { label: "Application Withdrawn", value: "Withdrawn", disabled: true },
  ],
  DealStatus: [
    { label: "New Application", value: "New Application", disabled: true },
    { label: "G2G/Submitted", value: "G2G/Submitted", disabled: true },
    { label: "Conditionally Accepted", value: "Conditionally Accepted", disabled: true },
    { label: "Unconditionally Accepted", value: "Unconditionally Accepted", disabled: true },
    { label: "Ready for Payment", value: "Ready for Payment", disabled: true },
    { label: "Fees Paid", value: "Fees Paid", disabled: true },
    { label: "Acceptance Statement", value: "Acceptance Statement", disabled: true },
    { label: "Visa Applied", value: "Visa Applied", disabled: true },
    { label: "Visa Approved", value: "Visa Approved", disabled: true },
    { label: "Visa Refused", value: "Visa Refused", disabled: true },
    { label: "Enrolled", value: "Enrolled", disabled: true },
    { label: "Application Rejected", value: "Application Rejected", disabled: true },
    { label: "Application Withdrawn", value: "Application Withdrawn", disabled: true },
  ],
  missingFields: [
    { label: "Personal - FirstName", value: "firstName", section: "personal" },
    { label: "Personal - LastName", value: "lastName", section: "personal" },
    { label: "Personal - Dob", value: "dob", section: "personal" },
    { label: "Personal - Gender", value: "gender", section: "personal" },
    { label: "Personal - Nationality", value: "nationality", section: "personal" },
    { label: "Personal - FirstLanguage", value: "firstLanguage", section: "personal" },
    { label: "Personal - MaritalStatus", value: "maritalStatus", section: "personal" },
    { label: "Personal - PassportNumber", value: "passportNumber", section: "personal" },
    { label: "Address - AddressWithRegion", value: "addressWithRegion", section: "address" },
    { label: "Address - City", value: "city", section: "address" },
    { label: "Address - Country", value: "country", section: "address" },
    { label: "Address - Province", value: "province", section: "address" },
    { label: "Address - PostalCode", value: "postalCode", section: "address" },
    { label: "Address - Email", value: "email", section: "address" },
    { label: "Address - Mobile", value: "mobile", section: "address" },
    { label: "Address - MobileCode", value: "mobileCode", section: "address" },
    { label: "Education - CountryOfEducation", value: "countryOfEducation", section: "education" },
    {
      label: "Education - HighLevelOfEducation",
      value: "highLevelOfEducation", section: "education"
    },
    { label: "Education - Grade", value: "grade", section: "education" },
    { label: "College - InstitutionCountry", value: "institutionCountry", section: "college" },
    { label: "College - InstitutionName", value: "institutionName" , section: "college"},
    { label: "College - Qualification", value: "qualification", section: "college" },
    { label: "College - Degree", value: "degree" , section: "college"},
    { label: "College - StartDate", value: "startDate" , section: "college"},
    { label: "College - EndDate", value: "endDate" , section: "college"},
    { label: "College - GraduationDate", value: "graduationDate", section: "college" },
    { label: "College - GradingSystem", value: "gradingSystem" , section: "college"},
    { label: "College - College score", value: "score", section: "college" },
    { label: "College - College Documents", value: "college_docs", section: "college" },
    { label: "Exam - Type", value: "examType", section: "exam" },
    { label: "Exam - DateOfIssue", value: "dateOfIssue", section: "exam" },
    { label: "Exam - TestScore", value: "testScore" , section: "exam"},
    { label: "Exam - Listening", value: "listening" , section: "exam"},
    { label: "Exam - Reading", value: "reading", section: "exam"},
    { label: "Exam - Writing", value: "writing" , section: "exam"},
    { label: "Exam - Speaking", value: "speaking", section: "exam" },
    { label: "Exam - Exam Documents", value: "exam_docs", section: "exam" },
    { label: "Background - IsVisaBeforeECA", value: "isVisaBeforeECA", section: "background" },
    { label: "Background - IsVisaRejectedECA", value: "isVisaRejectedECA", section: "background" },
    { label: "Work Experience - Company Name", value: "companyName", section: "work" },
    { label: "Work Experience - Designation", value: "designation", section: "work" },
    { label: "Work Experience - JoinDate", value: "joinDate", section: "work" },
    { label: "Work Experience - EndDate", value: "endDate", section: "work" },
    { label: "Work Experience - Documents", value: "work_docs", section: "work" },
  ],
  docMissingFields: [
    { label: "College - College Documents", value: "college_docs", section: "college" },
    { label: "Exam - Exam Documents", value: "exam_docs", section: "exam" },
    { label: "Work Experience - Documents", value: "work_docs", section: "work" },
    { label: "Transcript", value: "transcript", section: "extra" },
    { label: "Portfolio", value: "portfolio", section: "extra" }
  ],
};
