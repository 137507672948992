import React from "react";
import { history, tools } from "_helpers";
import moment from "moment";
import { envConfig, eduTechConfig } from "_config/config";
// Import confirmAlert utils
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

// Import Snackbar utils
import SnackbarUtils from "_helpers/snackbar";
import { restServices } from "./apiServices";
import * as Services from "_config/api";
import jsonRole from "json/roles.json";
let employeeList = {
  active: [],
  sub: [],
};

export const common = {
  loadImg(img) {
    let result = require("img/" + img);
    return result;
  },

  loadEduImage(img) {
    return envConfig.EDUTECH_S3_URL + img;
  },

  evaluateEmailTemplates(fieldList, content, searchField, replaceField) {
    return new Promise(async (resolve, reject) => {
      if (fieldList) {
        let fields = fieldList;
        content = await common.replaceTemplateContent(
          content,
          fieldList,
          searchField,
          replaceField
        );
      }
      resolve(content);
    });
  },

  replaceTemplateContent(content, fields, searchField, replaceField) {
    return new Promise(async (resolve, reject) => {
      let replacedContent = "";
      replacedContent = content.replace(
        /([\$(])([\{(])(.+?)([\})])/g,
        (match, p1, p2, p3, p4, offset, string) =>
          common.fieldStringReplacer(
            match,
            p1,
            p2,
            p3,
            p4,
            offset,
            string,
            fields,
            searchField,
            replaceField
          )
      );

      resolve(replacedContent);
    });
  },

  fieldStringReplacer(
    match,
    p1,
    p2,
    p3,
    p4,
    offset,
    string,
    fields,
    searchField,
    replaceField
  ) {
    let idx = fields.findIndex((f) => {
      return f[searchField] === match.replace("${", "").replace("}", "");
    });
    if (idx == -1) {
      idx = fields.findIndex((f) => {
        return (
          //f[searchField] + "Desc" === match.replace("${", "").replace("}", "")
          f[searchField] === match.replace("${", "").replace("}", "")
        );
      });
    }
    let fieldName = "";
    if (fields[idx] && fields[idx].attr.lookup) {
      if (searchField == "key" && fields[idx].attr.lookup !== "") {
        searchField = "key";
      }
      if (replaceField == "key" && fields[idx].attr.lookup !== "") {
        replaceField = "key";
      }
    }
    if (idx >= 0) {
      if (searchField !== "key" && fields[idx].attr.lookup !== "") {
        fieldName = fields[idx][replaceField]; // + "Desc";
      } else {
        fieldName = fields[idx][replaceField];
      }
    }
    return p1 + p2 + fieldName + p4;
  },

  checkFieldPermission(attr) {
    let userData = common.authInfo();
    let role = userData.roleId || 0;
    let access = "";
    let permissions = attr.permission ? attr.permission : [];

    permissions.map((obj) => {
      if (obj.role == role) {
        access = obj.access;
      }
    });
    return access !== "DontShow";
  },

  checkPermission(permissions, module) {
    let modules = module.split(":");
    let allow = "2"; // deafult access denied
    let checkingConstant = permissions;
    try {
      if (permissions) {
        modules.map((m) => {
          if (checkingConstant[m]) {
            allow = checkingConstant[m]; //this.checkSetPermission(checkingConstant[m]);
            checkingConstant = checkingConstant[m];
          }
          return m;
        });
      }
      return allow;
    } catch (err) {
      common.snack("E", err.message);
      return allow;
    }
  },

  evaluatePermissions(permissions, result = {}) {
    return new Promise(async (resolve, reject) => {
      if (permissions && permissions.length > 0) {
        const finalResult = await common.evaluateUserPermissions(
          permissions,
          result
        );
        resolve(finalResult);
      }
    });
  },

  evaluateUserPermissions(permissions, result) {
    return new Promise((resolve, reject) => {
      permissions.map(async (item, index) => {
        let name = item.child || item.name || item.access;
        name = name.toLowerCase();
        if (!result[name]) result[name] = {};
        let active = this.checkSetPermission(item.GroupActive);
        //item.GroupActive && item.GroupActive === true ? true : false;
        result[name].active = active;
        if (item.subLevel && item.subLevel.length > 0) {
          await this.evaluatePermissions(item.subLevel, result[name]);
          // item.subLevel = subLevel;
          resolve(result);
        } else if (item.permissions && item.permissions.length > 0) {
          await this.evaluatePermissions(item.permissions, result[name]);
          // item.permissions = perms;
          resolve(result);
        } else {
          resolve(result);
        }
        return item;
      });
    });
  },

  evaluateUserPermissionsAll(permissions, result) {
    return new Promise((resolve, reject) => {
      permissions.map(async (item, index) => {
        let name = item.child || item.name || item.access || item.GroupDesc;
        name = name.toLowerCase();
        if (!result[name]) result[name] = {};
        let active = this.checkSetPermission(item.GroupActive);
        //item.GroupActive && item.GroupActive === true ? true : false;
        result[name].active = active;
        if (
          item.CholaUserGroupsChildView &&
          item.CholaUserGroupsChildView.length > 0
        ) {
          await this.evaluateUserPermissionsAll(
            item.CholaUserGroupsChildView,
            result[name]
          );
          // item.subLevel = subLevel;
          resolve(result);
        } else {
          resolve(result);
        }
        return item;
      });
    });
  },

  evaluateUserPermissionsEdit(permissions, result) {
    return new Promise((resolve, reject) => {
      try {
        permissions.map(async (item, index) => {
          // let name = item.child || item.name || item.access || item.GroupDesc;
          const idx = result.findIndex((r) => {
            let name = r.child || r.name || r.access || r.GroupDesc;
            return item.GroupDesc === name;
          });
          let active = this.checkSetPermission(item.GroupActive);
          if (idx >= 0) {
            result[idx].Active = active;
            result[idx].Enabled = false;
            result[idx].GroupId = item.GroupId;
            result[idx].GroupParentId = item.GroupParentId;
            result[idx].UserGroupId = item.UserGroupId;

            if (
              item.CholaUserGroupsChildView &&
              item.CholaUserGroupsChildView.length > 0
            ) {
              await this.evaluateUserPermissionsEdit(
                item.CholaUserGroupsChildView,
                result[idx].subLevel || result[idx].permissions
              );
              // item.subLevel = subLevel;
              resolve(result);
            } else {
              resolve(result);
            }
          } else {
            // result[idx].Active = active;
            // resolve(result);
          }
          return item;
        });
      } catch (e) {}
    });
  },

  evaluateRolePermissions(permissions, result) {
    return new Promise((resolve, reject) => {
      permissions.map(async (item, index) => {
        let idx = result.findIndex((r) => {
          let name = r.child || r.name || r.access;
          return name === item.GroupDesc;
        });

        // name = name.toLowerCase();
        // if (!result[name]) result[name] = {};
        // let active = this.checkSetPermission(item.GroupActive);
        if (idx >= 0) {
          result[idx].GroupId = item.GroupId;
          result[idx].GroupParentId = item.GroupParentId;
          if (item.Active == "Y" || item.Active == "N") {
            result[idx].Active = item.Active
              ? item.Active == "Y"
                ? "1"
                : "2"
              : "2";
          } else {
            result[idx].Active = item.Active ? item.Active : "2";
          }
        }
        // result[name].active =÷ active;

        if (
          item.CholaGetGroupsChildView &&
          item.CholaGetGroupsChildView.length > 0
        ) {
          let nextLevel = result[idx]
            ? result[idx].subLevel || result[idx].permissions
            : [];
          if (nextLevel) {
            await this.evaluateRolePermissions(
              item.CholaGetGroupsChildView,
              nextLevel
            );
          }
          // item.subLevel = subLevel;
          resolve(result);
        } else {
          resolve(result);
        }
        return item;
      });
    });
  },

  evaluateRolePermissionsToUser(permissions, result) {
    return new Promise((resolve, reject) => {
      permissions.map(async (item, index) => {
        let idx = result.findIndex((r) => {
          let name = r.child || r.name || r.access;
          return name === item.GroupDesc;
        });

        // name = name.toLowerCase();
        // if (!result[name]) result[name] = {};
        let active = this.checkSetPermission(item.Active);
        if (idx >= 0) {
          result[idx].GroupId = item.GroupId;
          result[idx].GroupParentId = item.GroupParentId;
          result[idx].Active = active;
        }
        // result[name].active =÷ active;

        if (
          item.CholaGetGroupsChildView &&
          item.CholaGetGroupsChildView.length > 0
        ) {
          let nextLevel = result[idx]
            ? result[idx].subLevel || result[idx].permissions
            : [];
          if (nextLevel) {
            await this.evaluateRolePermissionsToUser(
              item.CholaGetGroupsChildView,
              nextLevel
            );
          }
          // item.subLevel = subLevel;
          resolve(result);
        } else {
          resolve(result);
        }
        return item;
      });
    });
  },

  mapUserPermissions(roles, userId, item) {
    return new Promise(async (resolve, reject) => {
      let idx = roles.findIndex((j) => {
        return (
          j.name === item.GroupDesc ||
          j.child === item.GroupDesc ||
          j.access === item.GroupDesc
        );
      });
      if (idx >= 0) {
        roles[idx].GroupId = item.GroupId;
        roles[idx].GroupParentId = item.GroupParentId;
        roles[idx].GroupActive = this.checkSetPermission(item.GroupActive);
        //item.GroupActive === "Y" || item.GroupActive === true ? true : false;
        if (roles[idx].subLevel && roles[idx].subLevel.length > 0) {
          await this.getRoles(item.GroupId, userId, roles[idx].subLevel);
          // roles[idx].subLevel = permissions;
        } else if (
          roles[idx].permissions &&
          roles[idx].permissions.length > 0
        ) {
          await this.getRoles(item.GroupId, userId, roles[idx].permissions);
          // roles[idx].permissions = permissions;
        }
        // else {
        //   resolve(item);
        // }
      }
      resolve(item);
    });
  },

  checkSetPermission(active) {
    let permission = envConfig.allPermissions[0].value; //all

    switch (active) {
      case null:
        permission = envConfig.allPermissions[0].value; //all
        break;
      case undefined:
        permission = envConfig.allPermissions[0].value; //all
        break;
      case "Y":
        permission = envConfig.allPermissions[0].value; //all
        break;
      case "N":
        permission = envConfig.allPermissions[1].value; //access denied
        break;
      default: //from api
        permission = active;
        break;
    }
    return permission;
  },

  getRoles(groupId, userId, roles = jsonRole.module) {
    return new Promise((resolve, reject) => {
      let filter = `?q=GroupParentId=${groupId};UserId=${userId}&limit=10&offset=0`;
      let url = encodeURI(
        envConfig.AUTH_REST_URL + Services.USER_ROLE_GROUPS + filter
      );
      restServices.getRequest(url, async (response) => {
        let items = [];
        if (response && response.items && response.items.length) {
          items = response.items;
        }
        Promise.all(
          items.map((item) => this.mapUserPermissions(roles, userId, item))
        ).then((result) => resolve(jsonRole.module));
      });
    });
  },

  /**
   * Pass userid and get user profile image from db
   */
  getUserProfileImg(userId) {
    if (userId) {
      let imgURL =
        envConfig.IMG_SERVLET_URL + "?imageId=" + userId + "&imageType=USER";
      return imgURL;
    } else {
      let result = require("img/work/user.png");
      return result;
    }
  },

  /**
   * Pass file path and get user profile image from db
   */
  getProfileImgFromPath(path) {
    if (path) {
      let imgURL = envConfig.SERVLET_URL + "?imagePath=" + path;
      return imgURL;
    } else {
      let result = require("img/work/user.png");
      return result;
    }
  },

  /**
   * Format date
   */
  formatDate(date, format, onlyMoment) {
    if (!format) format = "D MMM - h:mm a";
    if (onlyMoment) {
      return moment.utc(date).local();
    }
    let formatedDate = moment.utc(date).local().format(format);
    return formatedDate;
  },

  formatDateAMPM(date, format, isFrmtAtString) {
    if (!format) format = "D MMM - h:mm a";
    let formatedDate = moment.utc(date).local().format(format);
    let localDate = new Date(moment.utc(date).local());

    var hours = localDate.getHours();
    var minutes = localDate.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = formatedDate + " | " + hours + ":" + minutes + " " + ampm;
    if (isFrmtAtString === true) {
      strTime = formatedDate + " at " + hours + ":" + minutes + " " + ampm;
    }
    return strTime;
  },

  /**
   * Convert to UTC date
   */
  convertUTCDate(date) {
    if (date == null || date == "") return null;
    let formatDate = moment.utc(date).format();
    return formatDate;
  },

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },

  /**
   * Check the response is valid or not
   */
  checkValidJSONReponse(response) {
    if (response.status === 401) {
      common.snack("E", "Something went wrong!");
      common.removeAuthStorage();
    } else if (response.status === 403) {
      //token expired
      localStorage.clear();
      window.location.href = "/login";
    } else if (response.status >= 200 && response.status <= 300) {
      return response.json();
    } else if (response.statusText === "Created") {
      return response.json();
    } else {
      response.text().then(function (text) {
        if (text) {
          if (text.includes("Error from PLSQL Call :E :")) {
            common.snack("E", text.substring(text.indexOf(":E :") + 4));
          } else {
            common.snack("E", text);
          }
        } else {
          common.snack("E", "Something went wrong!");
        }
      });
    }
  },

  /**
   * Get TransactionTypeId
   */
  getTransactionTypeId(type) {
    try {
      let decTransType = localStorage.getItem("tData")
        ? tools.deCrypt(localStorage.getItem("tData"))
        : null;
      if (decTransType && decTransType.length > 0) {
        let data = decTransType.filter((data) => {
          return data.code === type;
        });
        return data[0].id;
      }
      return null;
    } catch (e) {
      return null;
    }
  },

  /**
   * Get Object name by Page
   */
  getObjectNameByPage(page) {
    switch (page) {
      case "campaign":
        return "CRM_CAMPAIGNS";
      case "lead":
        return "CRM_LEADS";
      case "enquiry":
        return "CRM_ENQUIRY";
      case "deal":
        return "CRM_OPPORTUNITY";
      case "quote":
        return "CRM_QUOTE";
      case "order":
        return "CRM_ORDER";
      case "invoice":
        return "CRM_INVOICE";
      case "customer":
        return "CUSTOMER";
      case "party":
        return "PARTY";
    }
  },

  /**
   * Get base64 content
   */
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let base64Content = reader.result;
      let base64String = base64Content.replace("data:", "").replace(/^.+,/, "");
      cb(base64String);
    };
    reader.onerror = function (error) {};
  },

  /**
   * Convert bytes to KB, MB, GB, ...
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  },

  /**
   * Set key value in localstorage
   */
  setLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },

  /**
   * Get value from localstorage based on key
   */
  getLocalStorageValue(obj, key) {
    let keyValue;
    if (obj) {
      let data = JSON.parse(localStorage.getItem(obj));
      keyValue = data ? data[key] : "";
    } else {
      keyValue = localStorage.getItem(key);
    }

    return keyValue;
  },

  /**
   * Convert number into words
   */
  convertNumberToWords(amount) {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      let value = "";
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  },

  /**
   * Snackbar message
   */
  snack(type, msg = "") {
    switch (type) {
      case "S":
        SnackbarUtils.success(msg);
        break;
      case "E":
        SnackbarUtils.error(msg);
        break;
      case "W":
        SnackbarUtils.warning(msg);
        break;
      case "I":
        SnackbarUtils.info(msg);
        break;
      case "T":
        SnackbarUtils.toast(msg);
        break;
      case "C":
        SnackbarUtils.closeAll();
        break;
    }
  },

  /**
   * Confirm delete message
   */
  confirmDelete(isMultiple, handleDeleteBlock) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Delete Confirmation</h1>
            {isMultiple && (
              <p>Are you sure you want to delete the selected items?</p>
            )}
            {!isMultiple && <p>Are you sure you want to delete this item?</p>}
            <button className="noBtn" onClick={onClose}>
              Cancel
            </button>
            <button
              className="yesBtn"
              onClick={() => {
                handleDeleteBlock(true);
                onClose();
              }}
            >
              Delete
            </button>
          </div>
        );
      },
    });
  },

  /**
   * Form list of options data
   */
  getOptionsData(data, label, value, labelName = "") {
    let optionsData = [];
    optionsData.push({
      label: labelName ? "Select" + " " + labelName : "Select",
      value: "",
    });

    if (data && data.length) {
      data.map((obj) => {
        let options = {
          label: obj[label],
          value: obj[value],
        };
        optionsData.push(options);
      });
    }
    return optionsData;
  },

  getMissingObject(data) {
    let result = data.reduce(function (r, a) {
      r[a.section] = r[a.section] || [];
      r[a.section].push(a.value);
      return r;
    }, Object.create(null));
    return result;
  },

  getMissingFields(mapField, isLead) {
    let mapFields = mapField.split(",");
    let missingFields = isLead
      ? eduTechConfig.missingFields
      : eduTechConfig.docMissingFields;
    let matches = missingFields.filter((obj) => mapFields.includes(obj.label));
    return matches;
  },

  enableLeadStatus(cStatus) {
    let enable = [];
    if (cStatus == "Processing") {
      enable = [
        "Complete Lead",
        "Application Rejected",
      ];
    } else if (cStatus == "Complete Lead") {
      enable = ["Application Rejected"];
    } else if (cStatus == "Application Rejected") {
      enable = [];
    }
    const newStatus = eduTechConfig.LeadStatus.map((p) =>
      enable.includes(p.label) ? { ...p, disabled: false } : p
    );
    return newStatus;
  },

  enableDealStatus(cStatus) {
    let enable = [];
    if (cStatus == "New Application") {
      enable = ["G2G/Submitted", "Application Rejected"];
    } else if (cStatus == "G2G/Submitted") {
      enable = ["Conditionally Accepted", "Unconditionally Accepted"];
    } else if (cStatus == "Conditionally Accepted") {
      enable = ["Unconditionally Accepted", "Application Rejected"];
    } else if (cStatus == "Unconditionally Accepted") {
      enable = ["Ready for Payment", "Application Rejected"];
    } else if (cStatus == "Fees Paid") {
      enable = ["Acceptance Statement"];
    } else if (cStatus == "Visa Approved") {
      enable = ["Enrolled"];
    } else if (cStatus == "Acceptance Statement") {
      enable = ["Visa Applied", "Visa Refused", "Visa Approved"];
    } else if (cStatus == "Visa Applied" || cStatus == "Visa Refused") {
      enable = ["Visa Approved", "Application Rejected"];
    } else if (
      cStatus == "Ready for Payment" ||
      cStatus == "Application Rejected" ||
      cStatus == "Application Withdrawn"
    ) {
      enable = [];
    }
    const newStatus = eduTechConfig.DealStatus.map((p) =>
      enable.includes(p.label) ? { ...p, disabled: false } : p
    );
    return newStatus;
  },

  getOptionsOnlyData(data, label, value, string = false) {
    let optionsData = [];

    if (data && data.length) {
      data.map((obj) => {
        let options = {
          label: obj[label],
          value: string ? obj[value].toString() : obj[value],
        };
        optionsData.push(options);
      });
    }
    return optionsData;
  },

  getOptionValue(data) {
    let result = [];
    if (typeof data === "object" && data !== null) {
      data.map((item) => {
        result.push(item.value);
      });
    }
    return result.join(",");
  },

  setOptionValue(data) {
    let result = [];
    data.split(",").map((item) => {
      result.push({ label: item, value: item });
    });
    return result;
  },

  removeItem(array, val) {
    const index = array.indexOf(val);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  },

  isDivider(key, val) {
    let x = key / val;
    return !(x % 1);
  },

  getParam(name) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let view = params.get(name);
    if (view) {
      return view;
    } else {
      return "";
    }
  },

  getCrmReturnQuery() {
    let view = common.getParam("view");
    let status = common.getParam("status");
    let paging = common.getParam("paging");
    let search = common.getParam("search");

    let url = "";
    if (view) url = "tab=general&view=" + view + "&";
    if (status) url += "status=" + status + "&";
    if (paging) url += "paging=" + paging + "&";
    if (search) url += "search=" + search;
    return url ? "?" + url : "";
  },

  // permission
  getPermission(props, type) {
    try {
      let permissions = props.permissions;
      let value = envConfig.allPermissions[0].value;
      return {
        read: common.checkPermission(permissions, type + ":r") === value,
        add: common.checkPermission(permissions, type + ":a") === value,
        update: common.checkPermission(permissions, type + ":u") === value,
        delete: common.checkPermission(permissions, type + ":d") === value,
        export: common.checkPermission(permissions, type + ":e") === value,
        import: common.checkPermission(permissions, type + ":i") === value,
        access: common.checkPermission(permissions, type + ":r") === value,
      };
    } catch (err) {
      common.snack("E", err.message);
      return {
        read: false,
        add: false,
        update: false,
        delete: false,
        export: false,
        access: false,
      };
    }
  },

  getPermissionData(permissions) {
    return {
      read: permissions.r || "2",
      add: permissions.a || "2",
      update: permissions.u || "2",
      delete: permissions.d || "2",
      import: permissions.i || "2",
      export: permissions.e || "2",
    };
  },

  validPermission(empId, type, userPermissions, employeesList, recordOwner) {
    let userData = common.authInfo();
    empId = empId && empId > 0 ? parseInt(empId) : 0;
    let UserName = userData.UserName;
    let permission = userPermissions[type];
    let allPermission = envConfig.allPermissions;
    let loggedInEmpId = userData.EmpId;
    let recordOwnerPermission =
      UserName === recordOwner && permission != allPermission[1].value;
    let access = false;

    switch (permission) {
      case allPermission[0].value: //all
        access = true;
        break;
      case allPermission[2].value: //personal
        if (empId == loggedInEmpId) {
          access = true;
        }
        break;
      case allPermission[3].value: //Personal + dept
        if (
          (type == "update" &&
            employeesList.active.length > 0 &&
            employeesList.active.includes(empId)) ||
          (type == "delete" &&
            employeesList.active.length > 0 &&
            employeesList.active.includes(empId))
        ) {
          access = true;
        }
        break;
      case allPermission[4].value: //personal, dept & sub dept
        if (
          (type == "update" && employeesList.sub.includes(empId)) ||
          (type == "delete" && employeesList.sub.includes(empId))
        ) {
          access = true;
        }
        break;
    }
    access = empId > 0 ? access : recordOwnerPermission;
    return access;
  },

  /**
   * Get Child List
   */
  getChildList(item, id) {
    return new Promise((resolve, reject) => {
      let filter = `?q=ParentId=${id}&limit=100&offset=0`;
      let children = item.children ? item.children : [];

      let url = encodeURI(
        envConfig.BASE_API + Services.CRM_TERRITORIES + filter
      );
      restServices.getRequest(url, async (response) => {
        if (response && response.items && response.items.length) {
          if (item.TerritoryId == id) {
            response.items.map((obj) => {
              let childData = {
                title: obj.TerritoryName,
                TerritoryId: obj.TerritoryId,
              };
              children.push(childData);
            });
            item["children"] = children;
          }
        }
        Promise.all(
          children.map((obj) => this.getChildList(obj, obj.TerritoryId)),
          children.map((item) => this.getEmpTerritoryFromSalesTeam(item, "sub"))
        ).then((result) => resolve(children));
      });
    });
  },

  getEmpTerritoryFromSalesTeam(item, key) {
    return new Promise((resolve, reject) => {
      let url = encodeURI(
        envConfig.BASE_API +
          Services.CRM_TERRITORY_TEAM +
          "?q=TerritoryId=" +
          item.TerritoryId
      );
      restServices.getRequest(url, async (response) => {
        item.employee = response.count;
        //employeeList.push(response.items);
        response.items.map((obj) => {
          employeeList[key].push(obj.EmployeeId);
        });
        if (key == "active") {
          response.items.map((obj) => {
            employeeList["sub"].push(obj.EmployeeId);
          });
        }

        resolve(item);
      });
    });
  },

  // Email functionalities
  sendEmail(data) {
    let userData = common.authInfo();
    // fields
    let sDate = data.startDate
      ? data.startDate
      : common.formatDate(Date.now(), "YYYY-MM-DDTHH:mm:ssZ");
    let eDate = data.endDate
      ? data.endDate
      : common.formatDate(Date.now(), "YYYY-MM-DDTHH:mm:ssZ");
    let fields = {
      FromUser: envConfig.SENDER_EMAIL,
      ToUser: data.email,
      Url: envConfig.SITE_URL,
      Subject: data.subject,
      MsgContent: data.content,
      Read: "N",
      Active: "Y",
      SendEmail: "Y",
      SendSms: "N",
      EmailSent: "N",
      SmsSent: "N",
      StartDate: sDate,
      EndDate: eDate,
      TenantId: userData.TenantId,
    };

    return new Promise(async (resolve, reject) => {
      let url = encodeURI(envConfig.BASE_API + Services.CHOLA_NOTIFICATIONS);
      restServices.postRequest(
        url,
        fields,
        (response) => {
          if (response) {
            resolve(1);
          } else {
            resolve(0);
          }
        },
        (error) => {
          resolve(0);
        }
      );
    });
  },

  // date and month
  getMonthList() {
    return [
      { label: "January", value: "JAN" },
      { label: "Febuary", value: "FEB" },
      { label: "March", value: "MAR" },
      { label: "April", value: "APR" },
      { label: "May", value: "MAY" },
      { label: "June", value: "JUN" },
      { label: "July", value: "JUL" },
      { label: "August", value: "AUG" },
      { label: "September", value: "SEP" },
      { label: "October", value: "OCT" },
      { label: "November", value: "NOV" },
      { label: "December", value: "DEC" },
    ];
  },

  getYearList(from) {
    let year = new Date().getFullYear();
    let result = [];
    for (let i = year - from; i <= year + 5; i++) {
      let obj = {
        label: i,
        value: i,
      };
      result.push(obj);
    }
    return result;
  },

  authInfo() {
    if (common.authCheck()) {
      let user = localStorage.getItem("userData");
      let userData = tools.deCrypt(user);
      return userData;
    }
  },

  getAuth() {
    let auth = null;
    if (common.authCheck()) {
      auth = true;
    }
    return auth;
  },

  authCheck() {
    let token = localStorage.getItem("userToken") || null;
    let auth = true;
    if (token === null) {
      auth = false;
    }
    return auth;
  },

  authValidate(auth) {
    let allow = true;
    let token = localStorage.getItem("userToken") || null;

    if (auth != null) {
      if (auth === true && token === null) {
        common.removeAuthStorage();
        allow = false;
      } else if (!auth && token != null) {
        history.push("/");
        allow = false;
      }
    } else if (!auth && token != null) {
      history.push("/");
      allow = false;
    }
    return allow;
  },

  removeAuthStorage() {
    localStorage.clear();
    history.push("/login");
  },

  redirect(page, time) {
    setTimeout(
      () => {
        window.location.href = common.url(page);
      },
      !time ? 1000 : time * 1000
    );
    return false;
  },

  url(url) {
    return process.env.PUBLIC_URL + url;
  },

  firstUpper(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  excludeFromArray(array1, array2, params) {
    var result = array1
      .filter(function (o1) {
        // filter out (!) items in array2
        return !array2.some(function (o2) {
          return o1.TerritoryId === o2.TerritoryId; // assumes unique id
        });
      })
      .map(function (o) {
        // use reduce to make objects with only the required properties
        // and map to apply this to the filtered array as a whole
        return params.reduce(function (newo, name) {
          newo[name] = o[name];
          return newo;
        }, {});
      });
    return result;
  },

  evalPermissions(access, recordTerId, resourceId) {
    let userData = tools.deCrypt(localStorage.getItem("userData"));
    let territory = userData["territory"];
    let isAdmin = userData["roleId"] === 0;

    let deptEmployess = userData["employeeList"];

    if (isAdmin) {
      return true;
    } else {
      if (access == "1") {
        return true;
      }
      if (access == "2") {
        return false;
      }
      if (access == "3") {
        //assigned records as personal records
        // console.log('recordTerId == resourceId :>> ', userData.EmpId === resourceId, userData.EmpId , resourceId);
        //return territory.includes(recordTerId);
        return userData.EmpId === resourceId;
      } else if (access == "4") {
        //personal + current department
        return (
          territory.includes(recordTerId) ||
          deptEmployess.active.includes(resourceId)
        );
      } else if (access == "5") {
        //personal + current department, its sub department
        return (
          deptEmployess.active.includes(resourceId) ||
          deptEmployess.sub.includes(resourceId)
        );
      }
    }
  },

  /**
   * insert string - empId
   */
  insertString(a, b, at) {
    var position = a.indexOf(at);

    if (position !== -1) {
      return a.substr(0, position + 2) + b + a.substr(position + 2);
    }

    return a;
  },
  //JSON parse
  reParse(data) {
    return JSON.parse(JSON.stringify(data));
  },
};

export const formFieldTitles = {
  text_input: "Text Input",
  select: "Select Box",
  auto_suggest: "Auto Suggest",
  rating: "Rating",
  check_box: "Check Box",
  date_input: "Date Input",
  text_select: "Group Control",
};
