import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
// includes
import { crm } from "_utils"
import { envConfig } from "_config/config";
import * as Services from "_config/api";
import { common, restServices, history } from "_helpers";
// pages
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
// css
import "./Header.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userObj: {
        UserName: "",
        FirstName: "",
        LastName: "",
        ProfileImg: "",
        Status: "Available",
        isMenuOpen: this.props.open ? this.props.open : false,
        isNotificationOpen: this.props.open ? this.props.open : false,
      },
      notificationLists: [],
      notificationCount: 0,
      totalRecords: 0,
      limit: 10,
      isLoading: false,
      intervalId: "",
      countIntervalId: "",
      userData: common.authInfo(),
      time: moment().format('HH:mm:ss A')
    };
  }
  componentDidMount() {
    const { userObj, userData } = this.state;

    setInterval(() => {
      this.setState({
        time: moment().format('HH:mm:ss A')
      })
    }, 1000);

    //let userData = common.authInfo();
    if (userData != null) {
      userObj["UserName"] = userData.FirstName + " " + userData.LastName;
      userObj["FirstName"] = userData.FirstName;
      userObj["LastName"] = userData.LastName;
      userObj["ProfileImg"] =
        envConfig.IMG_SERVLET_URL +
        "?imageId=" +
        userData.UserId +
        "&imageType=USER";
      this.setState({
        userObj: userObj,
      });
    }

    let intervalId, countIntervalId;

    intervalId = setInterval(this.getNotification, 120000);
    if (this.state.countIntervalId <= 0) {
      countIntervalId = 0;
      this.getNotificationCount();
    } else {
      countIntervalId = setInterval(this.getNotificationCount, 120000);
    }
    this.setState({ intervalId, countIntervalId });
  }

  componentWillUnmount() {
    const { intervalId, countIntervalId } = this.state;
    clearInterval(intervalId);
    clearInterval(countIntervalId);
  }

  getNotificationCount = () => {
    const { limit, userData } = this.state;
    this.setState({ isLoading: true });
    let pageLimit = limit;
    let empId = userData.EmpId;
    let emailId = userData.UserName;
    let filter = `?q=ToUser=${empId} OR ${emailId};Read=N&totalResults=true&orderBy=CreatedOn:desc&limit=${pageLimit}&offset=0`;
    let url = encodeURI(
      envConfig.BASE_API + Services.CHOLA_NOTIFICATIONS + filter
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            notificationCount: response.totalResults,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  getNotification = (loadMore) => {
    const { limit, userData } = this.state;
    this.setState({ isLoading: true });
    let pageLimit = limit;
    if (loadMore) {
      pageLimit = pageLimit + 10;
      this.setState({
        limit: pageLimit,
      });
    }
    let empId = userData.EmpId;
    let emailId = userData.UserName;
    let filter = `?q=ToUser=${empId} OR ${emailId}&totalResults=true&orderBy=CreatedOn:desc&limit=${pageLimit}&offset=0`;
    let url = encodeURI(
      envConfig.BASE_API + Services.CHOLA_NOTIFICATIONS + filter
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            totalRecords: response.totalResults,
            notificationLists: response.items,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  // Control the logged user menu list
  handleUserMenu = () => {
    const { isMenuOpen } = this.state;
    this.setState({
      isMenuOpen: !isMenuOpen,
    });
  };

  // Use to close the user menu list
  handleClose = () => {
    this.setState({
      isMenuOpen: false,
    });
  };

  // Control the Notification list
  NotificationList = () => {
    const { isNotificationOpen } = this.state;
    this.setState(
      {
        isNotificationOpen: !isNotificationOpen,
      },
      (_) => {
        this.getNotification(false);
      }
    );
  };

  // Use to close the user menu list
  NotificationListClose = () => {
    this.setState({
      isNotificationOpen: false,
    });
  };

  // Logout user
  logoutUser = () => {
    localStorage.clear();
    window.location = "/";
  };

  readNotification = (item) => {
    if (item.Read == "N") {
      let url =
        envConfig.BASE_API + Services.CHOLA_NOTIFICATIONS + "/" + item.Id;
      let data = {
        Read: "Y",
      };
      restServices.patchRequest(
        url,
        data,
        (response) => {
          if (response) {
            this.getNotification(false);
          }
        },
        (error) => {
          common.snack("E", error);
        }
      );
    }
  };

  setMenu() {
    let menu = localStorage.getItem("myMenu");
    localStorage.setItem("myMenu", menu == "true" ? false : true);
    this.props.onMenuChange();
  }

  activePage() {
    let path = history.location.pathname;
    let page = path.split("/");
    return common.firstUpper(page[1]);
  }

  isBack() {
    let path = history.location.pathname;
    let page = path.split("/");
    if (path.match('task') || path.match('work')) {
      return (page.length > 2) ? true : false
    } else {
      return (page.length > 3) ? true : false
    }
  }

  goBack() {
    history.goBack();
  }

  render() {
    const {
      userObj,
      isMenuOpen,
      isNotificationOpen,
      notificationLists,
      notificationCount,
      totalRecords,
      isLoading,
    } = this.state;
    return (
      <header className="header">
        <Row>
          <Col md={6} className="header-left">
            <ul className="hl-list">
              <li>
                <img className="open-icon pointer" src={common.loadImg("open-menu.png")} alt="open-menu" onClick={() => this.setMenu()} />
              </li>
              <li>
                <p>OSMO CRM</p>
              </li>
              {/* <li>
                <p>{this.activePage()}</p>
              </li> */}
              {this.isBack() && (
                <li><button className="btn btn-back" onClick={() => { this.goBack() }}><span>{"<<"}</span> Back{" "}</button></li>
              )} 
            </ul>
          </Col>
          <Col md={6} className="text-right">
            <ul className="hr-list">
              <li>
                <h5>{moment().format('DD/MM/yyyy')}</h5>
              </li>
              <li className="clock-time">
                <h5>{this.state.time}</h5>
              </li>
              <li>
                <Link to="#" onClick={this.NotificationList} className="notification-icn">
                  <img className="header-icon" src={common.loadImg("bell.png")} alt="bell" />
                  {notificationCount > 0 &&
                    <span className="notification-count">{notificationCount}</span>
                  }
                </Link>
                <ul
                  className={
                    isNotificationOpen
                      ? "drop-down notification-list active"
                      : "drop-down notification-list"
                  }
                >
                  <li className="dropdown-header">
                    <h5>Notifications</h5>
                    <Link
                      className="close-icon"
                      to="#"
                      onClick={this.NotificationListClose}
                    >
                      <img src={common.loadImg("deactive.svg")} alt="Close" />
                    </Link>
                  </li>
                  <li>
                    <div className="notification-block">
                      {notificationLists &&
                        notificationLists.length > 0 &&
                        notificationLists.map((item, key) => {
                          return (
                            <div
                              className={
                                "notification-item d-flex " +
                                (item.Read == "N" && "notification-item-active")
                              }
                              key={key}
                              onClick={() => this.readNotification(item)}
                            >
                              <div className="notification-img">
                                <img src={common.loadImg("male-employee.png")} alt="bell" />
                              </div>
                              <div className="notification-info ml-3">
                                <h6 className="mb-0">
                                  <span>From: </span>
                                  {item.FromUser}
                                </h6>
                                {/* <h6 className="mb-0"><span>To: </span>{item.ToUser}</h6> */}
                                {/* <p className="mb-12">{item.Subject}</p> */}
                                <div className="d-flex justify-content-between">
                                  <p className="mb-0">
                                    {/* <img src={ProfileMsg} className="mr-2" />
                                  <b>Opened</b> Lead Welcome */}
                                    {item.Subject}
                                  </p>
                                  <p className="mb-0">
                                    {common.formatDateAMPM(
                                      item.CreatedOn,
                                      "DD/MM/YYYY",
                                      false
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {notificationLists.length > 0 && (
                        <div className="load-more-wrap">
                          {!isLoading &&
                            notificationLists &&
                            notificationLists.length > 0 &&
                            notificationLists.length !== totalRecords && (
                              <Link
                                to="#"
                                onClick={() => this.getNotification(true)}
                                className="load-more"
                              >
                                {" "}
                                Load More{" "}
                              </Link>
                            )}
                          <div className="paging-text">
                            1 to {notificationLists.length} of {totalRecords}{" "}
                            entries
                          </div>
                        </div>
                      )}
                      {isLoading && <Loader />}
                      {!isLoading &&
                        notificationLists &&
                        notificationLists.length == 0 && (
                          <div className="formTabSection position-r">
                            <Paper style={styles.boxLayout}>
                              <NoRecordFound />
                            </Paper>
                          </div>
                        )}
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={this.handleUserMenu}
                  className="media login-user"
                >
                  <div className="media-body text-left">
                    <p className="user-name">{userObj.FirstName}</p>
                    <p className="user-status">{userObj.Status}</p>
                  </div>
                  <img
                    className="profile-icon ml-3"
                    src={userObj.ProfileImg}
                    alt="profile"
                  />
                </Link>
                <ul
                  className={
                    isMenuOpen ? "drop-down user-menu-list active" : "drop-down user-menu-list"
                  }
                >
                  <li>
                    <Link to="/profile" onClick={this.handleClose}>
                      My Profile
                    </Link>
                  </li>
                  <li>
                    <Link to="/setup/hrms" onClick={this.handleClose}>
                      Settings
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={this.logoutUser}>
                      Logout
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </Col>
        </Row>
      </header>
    );
  }
}

// css
const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

export default Header;
